import { forwardRef } from "react";
import "./PingTextArea.scss";

type PingTextareaProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

const PingTextarea = forwardRef<HTMLTextAreaElement, PingTextareaProps>(
  (props, ref) => {
    return (
      <textarea
        ref={ref}
        className="PingTextarea PingTextarea--Small PingTextarea--Rounded"
        {...props}
      />
    );
  }
);

export { PingTextarea };
