import React from "react";
import { useHistory } from "react-router-dom";
import "./NotFoundPage.scss";

const NotFoundPage: React.FC = () => {
  const history = useHistory();

  const handleGoHome = () => {
    history.push("/");
  };

  return (
    <div className="not-found">
      <h1 className="not-found__title">404</h1>
      <p className="not-found__text">Oops! Page not found</p>
      <button onClick={handleGoHome} className="not-found__button">
        Go Home
      </button>
    </div>
  );
};

export default NotFoundPage;
