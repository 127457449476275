// @ts-nocheck
import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import debounce from "lodash/debounce";

const TOAST_STYLES = {
  minWidth: "300px",
};

const resetErrorDisplayed = debounce(() => {
  // only displace single error when backend goes down
  window.hasDisplayedApiError = false;
}, 3000);

export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      if (!window.hasDisplayedApiError) {
        let errorMsg = "";
        if (action.payload?.originalStatus === 500) {
          errorMsg =
            "A server error has occurred. Our development team has been notified. Please try again later.";
        } else {
          errorMsg =
            action.payload?.data?.detail ||
            "There was a problem with your request.";
        }

        toast.error(errorMsg, { style: TOAST_STYLES });

        window.hasDisplayedApiError = true;
        resetErrorDisplayed();
      }
    }

    return next(action);
  };
