import cx from "classnames";

import "./MainContent.scss";

type MainContentProps = {
  hasTopPadding?: boolean;
  paddingSize?: "normal" | "slim";
  className?: string;
  children?: React.ReactNode;
};

/**
 * Main content area for the application.
 *
 * All this does is wrap the children in a `<main>` tag and apply some styling.
 * This is required because we need to apply some specific styles to many of our
 * main content areas.
 */
export const MainContent = ({
  hasTopPadding = true,
  paddingSize = "normal",
  className,
  children,
}: MainContentProps) => {
  const classes = cx("MainContent", className, {
    "MainContent--HasTopPadding": hasTopPadding,
    "MainContent--PaddingNormal": paddingSize === "normal",
    "MainContent--PaddingSlim": paddingSize === "slim",
  });
  return <main className={classes}>{children}</main>;
};
