import { useEffect } from "react";
import { useDispatch, Provider } from "react-redux";

import {
  useHistory,
  useLocation,
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import {
  setAccessToken,
  RouterContext,
  RootWrapper,
  useAuth,
  PingToaster,
} from "@repo/ping-react-js";

import { setupStore } from "services/store";
import { setIsEmployee } from "reducers/settings";
import { useAppSelector } from "utils/redux";
import { NotFoundPage } from "features/misc-pages";
import { FRONT_END_BASE_URL } from "constants/index";

import PingVisionSubmissionGrid from "features/PingVisionSubmissionGrid";
import PingVisionSubmissionDashboard from "features/submission-dashboard/PingVisionSubmissionDashboard";

const store = setupStore();

const PingVisionRoutes = () => {
  const { accessToken, ssoUser } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (accessToken) {
      dispatch(setAccessToken(accessToken));
    }
  }, [accessToken, dispatch]);

  useEffect(() => {
    dispatch(
      setIsEmployee(ssoUser?.email?.includes("@pingintel.com") ?? false),
    );
  }, [dispatch, ssoUser]);

  // We want to wait until the `accessToken` is set in redux before rendering
  // the routes. Otherwise, the `prepareHeaders()` function in
  // `fetchBaseQuery()` will not have the `accessToken` available to set the
  // `Authorization` header.
  const reduxAccessToken = useAppSelector((state) => state.auth.accessToken);
  if (!reduxAccessToken) {
    return null;
  }

  return (
    <RouterContext.Provider value={{ location, history }}>
      <Switch>
        <Route path={`${FRONT_END_BASE_URL}/:slug([a-zA-Z-]+)`} exact>
          <PingVisionSubmissionDashboard />
        </Route>
        <Route path={`${FRONT_END_BASE_URL}/i/:pingId([0-9a-zA-Z-]+)`} exact>
          <PingVisionSubmissionDashboard />
        </Route>

        <Route
          path={`${FRONT_END_BASE_URL}/:teamId([0-9a-zA-Z-]+)/views/:slug([a-zA-Z-]+)`}
          exact
        >
          <PingVisionSubmissionDashboard />
        </Route>
        <Route
          path={`${FRONT_END_BASE_URL}/:teamId([0-9a-zA-Z-]+)/views/:slug([a-zA-Z-]+)/:pingId([0-9a-zA-Z-]+)`}
          exact
        >
          <PingVisionSubmissionDashboard />
        </Route>

        <Route path="/">
          <Redirect to={`${FRONT_END_BASE_URL}/my-issues`} />
        </Route>
        <Route path="/dashboard">
          <Redirect to="/" />
        </Route>
        <Route path="/grid">
          <PingVisionSubmissionGrid />
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
    </RouterContext.Provider>
  );
};

const Root = () => {
  return (
    <RootWrapper VITE_APP_AUTH_URL={import.meta.env.VITE_APP_AUTH_URL}>
      <Provider store={store}>
        <BrowserRouter>
          <PingToaster position="bottom-right" />
          <PingVisionRoutes />
        </BrowserRouter>
      </Provider>
    </RootWrapper>
  );
};

export default Root;
