import { useMemo } from "react";
import { useLogoutFunction } from "@propelauth/react";

import { SiteHeaderActionButton } from "./SiteHeaderActionButton";

import "./SiteHeaderActions.scss";

export type SiteHeaderActionItem = {
  title: string;
  iconName: string;
  onClick: () => void;
};

type SiteHeaderActionsProps = {
  items: SiteHeaderActionItem[];
};

export const SiteHeaderActions = ({ items }: SiteHeaderActionsProps) => {
  const logout = useLogoutFunction();

  const logoutAction = useMemo(
    () => ({
      title: "Logout",
      onClick: () => {
        logout(false);
      },
      iconName: "logout",
    }),
    [logout]
  );

  const mergedItems = useMemo(
    () => [...items, logoutAction],
    [logoutAction, items]
  );

  return (
    <div className="SiteHeaderActions">
      {mergedItems.map((item, index) => (
        <SiteHeaderActionButton
          key={index}
          onClick={item.onClick}
          title={item.title}
          iconName={item.iconName}
          tooltipPlacement="bottom"
        />
      ))}
    </div>
  );
};
