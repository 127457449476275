import { useMemo } from "react";
import { format } from "date-fns";

import { PAPERTRAIL, SUBMISSION_SOURCE_TYPES, S3_RESULTS_BUCKET } from "constants/SubmissionConstants";

import { GridButton, PingMaterialIcon, SSOALink } from "@repo/ping-react-js";

type PingColumnsProps = {
  accessToken: string;
  claimSubmission: (id: string) => void;
  changeSubmissionStatus: (id: string, status: string) => void;
  baseUrl: string;
  env: string;
  isPingEmployee: boolean;
};


const getPingVisionColumns = ({
  accessToken,
  claimSubmission,
  changeSubmissionStatus,
  baseUrl,
  env,
  isPingEmployee,
}: PingColumnsProps) => {
  return [
    {
      key: "id",
      name: "ID",
      frozen: true,
      resizable: false,
      width: 150,
    },
    {
      key: "team__name",
      name: "Team",
      frozen: true,
      resizable: true,
    },
    {
      key: "row_actions",
      name: "Actions",
      resizable: false,
      width: 200,
      renderCell: (props: any) => {
        const is_ping_maps_ready = props.row?.ping_maps?.status === "R";
        const ping_maps_status = `${props.row?.ping_maps?.status_display} - ${props.row?.ping_maps?.status_pct_complete}%`;
        const global_request_id = props.row?.global_request_id;

        return (
          <div className="PingVisionApp__PingActionCell">
            {isPingEmployee && (
              <>
                <GridButton
                  label={
                    <PingMaterialIcon
                      iconName="folder"
                      href={`https://us-east-1.console.aws.amazon.com/s3/buckets/${S3_RESULTS_BUCKET[env]}?region=us-east-1&bucketType=general&prefix=submissiondocuments/${props.row["pk"]}/&showversions=false`}
                      target="_blank"
                    />
                  }
                  title="Debug: AWS S3 Storage"
                />
                <GridButton
                  label={
                    <PingMaterialIcon
                      iconName="receiptlong"
                      href={
                        global_request_id
                          ? `https://my.papertrailapp.com/groups/${PAPERTRAIL[env]}/events?q=${global_request_id}`
                          : null
                      }
                      target="_blank"
                    />
                  }
                  title="Debug: Papertrail Logs"
                  disabled={!global_request_id || !PAPERTRAIL[env]}
                  disabledTitle="No Global Request ID or Papertrail ID not set"
                />
              </>
            )}
            <GridButton
              label={
                <PingMaterialIcon
                  iconName="room"
                  href={props.row?.ping_maps?.url}
                  target="_blank"
                />
              }
              title="Open Ping Maps for this SOV"
              disabled={!is_ping_maps_ready}
              disabledTitle={ping_maps_status}
            />
            {/* <GridButton
              key="claim-submission"
              label={<PingMaterialIcon iconName="lock" />}
              title="Claim Submission"
              onClick={() => {
                claimSubmission(props.row.pk);
              }}
            /> */}
          </div>
        );
      },
    },
    {
      key: "workflow_status__name",
      name: "Status",
      resizable: true,
      renderCell: (props: any) => {
        return props.row.workflow_status__name;
      },
    },
    // {
    //   key: "transition_to",
    //   name: "Transition To",
    //   resizable: true,
    //   renderCell: (props: any) => {
    //     const transitionTo = {
    //       ...{ 0: "" },
    //       ...(props.row?.actions?.transition_to || {}),
    //     };
    //     return (
    //       <div className="PingVisionApp__PingActionCell">
    //         <select
    //           onChange={(e) =>
    //             changeSubmissionStatus(props?.row?.pk, e.target.value)
    //           }
    //         >
    //           {Object.entries(transitionTo).map(([value, label]) => (
    //             <option key={value} value={value}>
    //               {label as string}
    //             </option>
    //           ))}
    //         </select>
    //       </div>
    //     );
    //   },
    // },
    {
      key: "division__short_name",
      name: "Division",
      resizable: true,
    },
    {
      key: "company__short_name",
      name: "Company",
      resizable: true,
    },
    {
      key: "data_readiness_score",
      name: "Readiness",
      resizable: true,
    },
    {
      key: "documents",
      name: "Documents",
      width: 350,
      resizable: false,
      renderCell: (props: any) => {
        if (!props.row.actions?.download_documents) {
          return <div>Cannot download documents.</div>;
        } else {
          return props.row.documents.map((doc: any, index: number) => {
            return (
              <SSOALink
                key={index}
                downloadUrl={doc.url}
                accessToken={accessToken}
                filename={doc.filename}
                label={doc.filename}
              />
            );
          });
        }
      },
    },
    {
      key: "source__source_type",
      name: "Source",
      resizable: true,
      renderCell: (props: any) => {
        return SUBMISSION_SOURCE_TYPES[props.row.source__source_type];
      },
    },
    {
      key: "created_time",
      name: "Created",
      resizable: true,
      renderCell: (props: any) => {
        const date = new Date(props.row.created_time);
        return format(date, "M/d/yyyy @ hh:mm a");
      },
    },
    {
      key: "modified_time",
      name: "Modified",
      resizable: true,
      renderCell: (props: any) => {
        const date = new Date(props.row.modified_time);
        return format(date, "M/d/yyyy @ hh:mm a");
      },
    },
  ];
};

export const usePingVisionColumns = ({
  accessToken,
  claimSubmission,
  changeSubmissionStatus,
  baseUrl,
  env,
  isPingEmployee,
}: PingColumnsProps) => {
  const columns = useMemo(
    () =>
      getPingVisionColumns({
        baseUrl,
        accessToken,
        claimSubmission,
        changeSubmissionStatus,
        env,
        isPingEmployee,
      }),
    [isPingEmployee, env, baseUrl, claimSubmission, changeSubmissionStatus]
  );

  return { columns };
};
