import { combineReducers, configureStore } from "@reduxjs/toolkit";

import { rtkQueryErrorLogger, authReducer } from "@repo/ping-react-js";

import settingsReducer from "reducers/settings";
import imboxReducer from "reducers/inbox";

import { api } from "services/pvSlice";

const baseReducers = {
  [api.reducerPath]: api.reducer,
  auth: authReducer,
  settings: settingsReducer,
  inbox: imboxReducer,
};

const rootReducer = combineReducers(baseReducers);

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        api.middleware,
        rtkQueryErrorLogger
      ),
    preloadedState,
  });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
