import {
  ChangeEvent,
  FormEvent,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState,
} from "react";
import cx from "classnames";
import SearchString from "search-string";
import { debounce } from "lodash";

import {
  PingButton,
  PingPopover,
  PingPopoverContent,
  PingPopoverTrigger,
  PingMaterialIcon,
  usePingPopoverContext,
  PingTextInput,
  PingSelectInput,
  PingSelectOptions,
} from "@repo/ping-react-js";

import "./PingSearchQueryBuilder.scss";

export const TEXT_SEGMENT_KEY = "text";
const MAIN_SEARCH_INPUT_NAME = "mainSearchInputText";

export type PingSearchQueryBuilderField = {
  label: string;
  fieldName: string;
  type: "range" | "dropdown" | "text";
  options?: PingSelectOptions;
};

type PingSearchQueryBuilderProps = {
  fields: PingSearchQueryBuilderField[];
  values: Record<string, string>;
  placeholder?: string;
  onChange: (data: Record<string, string> | null) => void;
};

const recordToSearchString = (record: Record<string, string>): string => {
  const searchString = SearchString.parse(record[TEXT_SEGMENT_KEY]);
  for (const [key, value] of Object.entries(record)) {
    if (key === TEXT_SEGMENT_KEY) {
      continue;
    }
    searchString.addEntry(key, value, false);
  }
  return searchString.toString();
};

export const PingSearchQueryBuilder = ({
  fields,
  values,
  placeholder = "Search…",
  onChange,
}: PingSearchQueryBuilderProps) => {
  const [mainSearchInputText, setMainSearchInputText] = useState(
    recordToSearchString(values)
  );
  const [isAdvancedFormOpen, setIsAdvancedFormOpen] = useState(false);
  const [advancedFormInitialValues, setAdvancedFormInitialValues] =
    useState<Record<string, string>>();

  const advancedFormId = useId();

  const inputFieldClasses = cx("PingSearchQueryBuilder__SearchField__Input", {
    "PingSearchQueryBuilder__SearchField__Input--IsOpen": isAdvancedFormOpen,
  });

  const triggerClasses = cx("PingSearchQueryBuilder__SearchField__Trigger", {
    "PingSearchQueryBuilder__SearchField__Trigger--IsOpen": isAdvancedFormOpen,
  });

  const debouncedOnChange = useMemo(() => debounce(onChange, 1000), [onChange]);

  // If the values are changed from outside, we update the main search input
  // text.
  useEffect(() => {
    setMainSearchInputText(recordToSearchString(values));
  }, [values]);

  // When the main input text changes, we parse the entered string, turn it into
  // key-value pairs, and call the debounced onChange callback.
  const onChangeMainSearchInputText = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setMainSearchInputText(e.target.value);

      const searchText = e.target.value?.trim();

      if (!searchText || searchText.length === 0) {
        debouncedOnChange(null);
        return;
      }

      debouncedOnChange(parseInputText(e.target.value));
    },
    [debouncedOnChange]
  );

  // When the clear button is clicked, we clear the input field and call the
  // onChange callback indicating an empty search.
  const onClear = useCallback(() => {
    setMainSearchInputText("");
    onChange(null);
  }, [onChange]);

  // When the advanced form is opened, we parse the text in the search field and
  // populate the advanced form with the parsed values.
  const onOpenPopover = useCallback(() => {
    if (isAdvancedFormOpen) {
      setIsAdvancedFormOpen(false);
      return;
    }

    // Parse the text in the search field and populate form field values.
    const parsedInput = parseInputText(mainSearchInputText);
    setAdvancedFormInitialValues(parsedInput);

    // Display the form.
    setIsAdvancedFormOpen(true);
  }, [isAdvancedFormOpen, mainSearchInputText]);

  // If the user hits the enter key in the search field, we do the same thing
  // that we do when the main input text changes. But we also cancel the
  // debounced onChange callback to ensure that the search is performed
  // immediately and only once.
  const onSubmitMainSearchInput = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      debouncedOnChange.cancel();
      const formData = new FormData(e.target as HTMLFormElement);
      const searchString = formData
        .get(MAIN_SEARCH_INPUT_NAME)
        .toString()
        .trim();

      if (!searchString || searchString.length === 0) {
        onChange(null);
        return;
      }

      onChange(parseInputText(searchString));
    },
    [debouncedOnChange, onChange]
  );

  // When the advanced form is submitted, we parse the form data and set the
  // main search input text to the parsed search string. We also call the
  // onChange callback with the form data.
  const onSubmitAdvancedForm = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const formData = new FormData(e.target as HTMLFormElement);

      const searchData = {};

      const mainSearchText = formData.get(TEXT_SEGMENT_KEY).toString().trim();
      if (mainSearchText && mainSearchText.length > 0) {
        searchData[TEXT_SEGMENT_KEY] = mainSearchText;
      }

      const searchString = SearchString.parse(
        mainSearchText && mainSearchText.length > 0 ? mainSearchText : ""
      );

      for (const [key, value] of formData.entries()) {
        if (key === TEXT_SEGMENT_KEY) {
          continue;
        }

        const valueAsString = value.toString().trim();
        if (!valueAsString || valueAsString.length === 0) {
          continue;
        }

        searchString.addEntry(key, value, false);
        searchData[key] = value;
      }

      // If searchData is empty, we don't need to set the main search input
      // text. We can also pass null to the onChange callback.
      if (Object.keys(searchData).length === 0) {
        setMainSearchInputText("");
        onChange(null);
        setIsAdvancedFormOpen(false);
        return;
      }

      setMainSearchInputText(searchString.toString());
      onChange(searchData);
      setIsAdvancedFormOpen(false);
    },
    [onChange]
  );

  return (
    <PingPopover
      placement="bottom-end"
      open={isAdvancedFormOpen}
      onOpenChange={setIsAdvancedFormOpen}
    >
      <form
        className="PingSearchQueryBuilder__SearchField"
        onSubmit={onSubmitMainSearchInput}
      >
        <PingTextInput
          type="search"
          inputClassName={inputFieldClasses}
          value={mainSearchInputText || ""}
          onChange={onChangeMainSearchInputText}
          placeholder={placeholder}
          name={MAIN_SEARCH_INPUT_NAME}
        />
        {mainSearchInputText && mainSearchInputText.length > 0 && (
          <button
            className="PingSearchQueryBuilder__SearchField__ClearIcon"
            type="reset"
            onClick={onClear}
          >
            <PingMaterialIcon
              className="PingSearchQueryBuilder__SearchField__ClearIcon__Icon"
              iconName="close"
            />
          </button>
        )}
        <PingPopoverTrigger className={triggerClasses} onClick={onOpenPopover}>
          <PingMaterialIcon
            className="PingSearchQueryBuilder__SearchField__Trigger__Icon"
            iconName="tune"
          />
        </PingPopoverTrigger>
      </form>

      <PingPopoverContent className="PingSearchQueryBuilder__Content">
        <PingSearchQueryBuilderAdvancedForm
          id={advancedFormId}
          fields={fields}
          values={advancedFormInitialValues}
          onSubmit={onSubmitAdvancedForm}
        />

        <div className="PingSearchQueryBuilder__Content__Footer">
          <PingSearchQueryBuilderCloseButton />
          <PingButton
            label="Search"
            variant="primary"
            type="submit"
            form={advancedFormId}
          />
        </div>
      </PingPopoverContent>
    </PingPopover>
  );
};

const parseInputText = (
  mainSearchInputText: string
): Record<string, string> => {
  // TODO: we don't support negations yet, so any negations that exist must be
  // marked as errors. Similarly, fields we don't support shouldn't exist in
  // the textbox.
  const searchString = SearchString.parse(mainSearchInputText);

  const parsedValues = {};
  for (const condition of searchString.getConditionArray()) {
    parsedValues[condition.keyword] = condition.value;
  }

  for (const ts of searchString.getTextSegments()) {
    if (!parsedValues[TEXT_SEGMENT_KEY]) {
      parsedValues[TEXT_SEGMENT_KEY] = "";
    }
    parsedValues[TEXT_SEGMENT_KEY] += ts.text;
    parsedValues[TEXT_SEGMENT_KEY] += " ";
  }
  if (parsedValues[TEXT_SEGMENT_KEY]) {
    parsedValues[TEXT_SEGMENT_KEY] = parsedValues[TEXT_SEGMENT_KEY].trim();
  }

  return parsedValues;
};

type PingSearchQueryBuilderAdvancedFormProps = {
  id: string;
  fields: PingSearchQueryBuilderField[];
  values: Record<string, string>;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
};

const PingSearchQueryBuilderAdvancedForm = ({
  id,
  fields,
  values,
  onSubmit,
}: PingSearchQueryBuilderAdvancedFormProps) => {
  const fullTextInputId = useId();
  return (
    <form
      className="PingSearchQueryBuilder__Content__Form"
      id={id}
      onSubmit={onSubmit}
    >
      <label htmlFor={fullTextInputId}>Contains text</label>
      <PingTextInput
        name={TEXT_SEGMENT_KEY}
        id={fullTextInputId}
        defaultValue={values[TEXT_SEGMENT_KEY]}
      />
      <PingSearchQueryBuilderFields fields={fields} values={values} />
    </form>
  );
};

const PingSearchQueryBuilderCloseButton = () => {
  const { setOpen } = usePingPopoverContext();

  return <PingButton label="Cancel" onClick={() => setOpen(false)} />;
};

type PingSearchQueryBuilderFieldsProps = {
  fields: PingSearchQueryBuilderField[];
  values: Record<string, string>;
};

const PingSearchQueryBuilderFields = ({
  fields,
  values,
}: PingSearchQueryBuilderFieldsProps) => {
  return fields?.map((f) => {
    if (f.type === "range") {
      return (
        <PingSearchQueryBuilderRangeField
          key={f.fieldName}
          label={f.label}
          fieldName={f.fieldName}
          values={values}
        />
      );
    } else if (f.type === "dropdown") {
      return (
        <PingSearchQueryBuilderSelectField
          key={f.fieldName}
          label={f.label}
          fieldName={f.fieldName}
          options={f.options}
          values={values}
        />
      );
    } else if (f.type === "text") {
      return (
        <PingSearchQueryBuilderTextField
          key={f.fieldName}
          label={f.label}
          fieldName={f.fieldName}
          values={values}
        />
      );
    } else {
      return null;
    }
  });
};

type PingSearchQueryBuilderRangeFieldProps = {
  label: string;
  fieldName: string;
  values: Record<string, string>;
  options?: PingSelectOptions;
};

type PingSearchQueryBuilderRangeType = "equal" | "greater" | "less" | "between";

const PingSearchQueryBuilderSelectField = ({
  label,
  fieldName,
  values,
  options,
}: PingSearchQueryBuilderRangeFieldProps) => {
  const [selected, setSelected] = useState<string>(
    values[fieldName]?.toString() || ""
  );
  return (
    <>
      <label htmlFor={fieldName}>{label}</label>
      <div className="PingSearchQueryBuilderRangeField__Inputs">
        <PingSelectInput
          className="PingSearchQueryBuilderRangeField__Inputs__Select"
          options={options || []}
          name={fieldName}
          value={selected}
          allowEmpty={true}
          onChange={(e) => setSelected(e.target.value)}
        />
      </div>
    </>
  );
};

const PingSearchQueryBuilderTextField = ({
  label,
  fieldName,
  values,
}: PingSearchQueryBuilderRangeFieldProps) => {
  return (
    <>
      <label htmlFor={fieldName}>{label}</label>
      <div className="PingSearchQueryBuilderRangeField__Inputs">
        <PingTextInput
          className="PingSearchQueryBuilderRangeField__Inputs__Text"
          type="text"
          name={fieldName}
          id={fieldName}
          defaultValue={""}
        />
      </div>
    </>
  );
};

const PingSearchQueryBuilderRangeField = ({
  label,
  fieldName,
  values,
}: PingSearchQueryBuilderRangeFieldProps) => {
  const equalFieldName = fieldName;
  const lessThanFieldName = `${fieldName}__lt`;
  const greaterThanFieldName = `${fieldName}__gt`;
  const lessThanEqualFieldName = `${fieldName}__lte`;
  const greaterThanEqualFieldName = `${fieldName}__gte`;

  const [rangeType, setRangeType] = useState<PingSearchQueryBuilderRangeType>(
    () => {
      if (
        values[lessThanEqualFieldName] &&
        values[greaterThanEqualFieldName] &&
        !values[lessThanFieldName] &&
        !values[greaterThanFieldName]
      ) {
        return "between";
      } else if (
        !values[lessThanEqualFieldName] &&
        !values[greaterThanEqualFieldName] &&
        !values[lessThanFieldName] &&
        values[greaterThanFieldName]
      ) {
        return "greater";
      } else if (
        !values[lessThanEqualFieldName] &&
        !values[greaterThanEqualFieldName] &&
        values[lessThanFieldName] &&
        !values[greaterThanFieldName]
      ) {
        return "less";
      }

      return "equal";
    }
  );

  const fromId = useId();
  const toId = useId();

  return (
    <>
      <label htmlFor={fieldName}>{label}</label>
      <div className="PingSearchQueryBuilderRangeField__Inputs">
        <PingSelectInput
          options={[
            { label: "Equal", value: "equal" },
            { label: "Greater than", value: "greater" },
            { label: "Less than", value: "less" },
            { label: "Between", value: "between" },
          ]}
          value={rangeType}
          onChange={(e) =>
            setRangeType(e.target.value as PingSearchQueryBuilderRangeType)
          }
        />
        {rangeType === "equal" && (
          <PingTextInput
            className="PingSearchQueryBuilderRangeField__Inputs__GreaterLess"
            type="number"
            name={equalFieldName}
            id={fieldName}
            defaultValue={values[equalFieldName]}
          />
        )}
        {rangeType === "greater" && (
          <PingTextInput
            className="PingSearchQueryBuilderRangeField__Inputs__GreaterLess"
            type="number"
            name={greaterThanFieldName}
            id={fieldName}
            defaultValue={values[greaterThanFieldName]}
          />
        )}
        {rangeType === "less" && (
          <PingTextInput
            className="PingSearchQueryBuilderRangeField__Inputs__GreaterLess"
            type="number"
            name={lessThanFieldName}
            id={fieldName}
            defaultValue={values[lessThanFieldName]}
          />
        )}
        {rangeType === "between" && (
          <div className="PingSearchQueryBuilderRangeField__Inputs__Between">
            <label className="ScreenReaderOnly" htmlFor={fromId}>
              {label} lower limit
            </label>
            <PingTextInput
              type="number"
              className="PingSearchQueryBuilderRangeField__Inputs__Between__TextInput"
              name={greaterThanEqualFieldName}
              id={fromId}
              defaultValue={values[greaterThanEqualFieldName]}
            />
            <div>–</div>
            <label className="ScreenReaderOnly" htmlFor={toId}>
              {label} upper limit
            </label>
            <PingTextInput
              type="number"
              className="PingSearchQueryBuilderRangeField__Inputs__Between__TextInput"
              name={lessThanEqualFieldName}
              id={toId}
              defaultValue={values[lessThanEqualFieldName]}
            />
          </div>
        )}
      </div>
    </>
  );
};
