import { useMemo } from "react";
import ReactModal from "react-modal";
import cx from "classnames";

import { DialogHeaderBar } from "@repo/ping-react-js";

import "./PingVisionModal.scss";

type PingVisionModalPropTypes = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  renderContent: () => React.ReactNode;
  renderFooter?: () => React.ReactNode;
  className?: string;
  contentOnly?: boolean;
};

export const PingVisionModal = ({
  isOpen,
  onClose,
  title,
  renderContent,
  renderFooter,
  className,
  contentOnly = false,
}: PingVisionModalPropTypes) => {
  ReactModal.setAppElement("#app");
  const classes = cx("PingVisionModal", className);

  const modalContent = useMemo(() => {
    return contentOnly ? (
      <div className="PingVisionModal__Content">{renderContent()}</div>
    ) : (
      <>
        <DialogHeaderBar className="PingVisionModal__Header">
          {title && (
            <div className="PingVisionModal__Header__Title">{title}</div>
          )}
        </DialogHeaderBar>
        <div className="PingVisionModal__Content">{renderContent()}</div>
        {renderFooter && (
          <DialogHeaderBar className="PingVisionModal__Footer" border="top">
            {renderFooter()}
          </DialogHeaderBar>
        )}
      </>
    );
  }, [contentOnly, renderContent, renderFooter, title]);

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose} // Add this line
      className={{
        base: classes,
        afterOpen: "PingVisionModal--afterOpen",
        beforeClose: "PingVisionModal--beforeClose",
      }}
      overlayClassName={{
        base: "PingVisionModal__Overlay",
        afterOpen: "PingVisionModal__Overlay--afterOpen",
        beforeClose: "PingVisionModal__Overlay--beforeClose",
      }}
      closeTimeoutMS={300}
    >
      {modalContent}
    </ReactModal>
  );
};
