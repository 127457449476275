import { HTMLAttributes } from "react";
import cx from "classnames";

import "./PVHeadings.scss";

type PVSectionHeadingProps = {} & HTMLAttributes<HTMLHeadingElement>;

const PVSectionHeading = ({ className, ...props }: PVSectionHeadingProps) => {
  return <h3 className={cx("PVSectionHeading", className)} {...props} />;
};

type PVSectionSubHeadingProps = {} & HTMLAttributes<HTMLHeadingElement>;

const PVSectionSubHeading = ({
  className,
  ...props
}: PVSectionSubHeadingProps) => {
  return <h4 className={cx("PVSectionSubHeading", className)} {...props} />;
};

export { PVSectionHeading, PVSectionSubHeading };
