import React, { useState, useRef, useEffect } from "react";
import { Paperclip, ArrowUp, Send } from "lucide-react";

import { useCreateNoteMutation } from "services/pvSlice";

import { SovDataType } from "ts-types/DataTypes";

import "./PVNotes.scss";

type PVNotesProps = {
  className?: string;
  selectedItem: SovDataType;
};

const PVNotes: React.FC<PVNotesProps> = ({ selectedItem, className = "" }) => {
  const [createNote] = useCreateNoteMutation();

  const [newNote, setNewNote] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Auto-resize textarea as content grows
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height =
        textareaRef.current.scrollHeight + "px";
    }
  }, [newNote]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newNote.trim() && !isSubmitting) {
      setIsSubmitting(true);

      const noteData = {
        id: selectedItem?.pk,
        text: newNote.trim(),
      };

      try {
        await createNote(noteData);

        if (textareaRef.current) {
          textareaRef.current.style.height = "auto";
        }
        setNewNote("");
      } catch (error) {
        console.error("Failed to create note", error);
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && (e.metaKey || e.ctrlKey)) {
      handleSubmit(e);
    }
  };

  return (
    <div className={`PVNotes ${className}`.trim()}>
      <form onSubmit={handleSubmit} className="PVNotes__form">
        <textarea
          ref={textareaRef}
          className="PVNotes__textarea"
          placeholder="Leave a comment..."
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isSubmitting}
        />
        <div className="PVNotes__actions">
          {false && (
            <button
              type="button"
              className="PVNotes__button"
              title="Attach file"
            >
              <Paperclip size={16} />
            </button>
          )}
          <button
            type="submit"
            className="PVNotes__button PVNotes__button--submit"
            disabled={!newNote.trim() || isSubmitting}
            title="Send comment (⌘/Ctrl + Enter)"
          >
            {isSubmitting ? (
              <ArrowUp size={16} className="PVNotes__button-icon--spinning" />
            ) : (
              <Send size={16} />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PVNotes;
