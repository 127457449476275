import cx from "classnames";
import { Placement } from "@floating-ui/react-dom";
import isNumber from "lodash/isNumber";

import { PingMaterialIcon } from "../../components/icons/PingMaterialIcon";

import {
  PingTooltip,
  PingTooltipContent,
  PingTooltipTrigger,
} from "../../components/panels/PingTooltip";

import "./SiteHeaderActionButton.scss";

type SiteHeaderActionButtonProps = {
  onClick: (e) => void;
  title: string;
  iconName: string;
  className?: string;
  containerOrientation?: "horizontal" | "vertical";
  tooltipPlacement?: Placement;
  isActive?: boolean;
  isDisabled?: boolean;
  disabledTooltip?: string;
  notificationCount?: number;
};

export const SiteHeaderActionButton = ({
  onClick,
  title,
  iconName,
  className,
  containerOrientation = "horizontal",
  tooltipPlacement,
  isActive = false,
  isDisabled = false,
  disabledTooltip,
  notificationCount,
}: SiteHeaderActionButtonProps) => {
  const classes = cx("SiteHeaderActionButton", className, {
    "SiteHeaderActionButton--Horizontal": containerOrientation === "horizontal",
    "SiteHeaderActionButton--Vertical": containerOrientation === "vertical",
    "SiteHeaderActionButton--Active": isActive,
    "SiteHeaderActionButton--Disabled": isDisabled,
  });

  return (
    <PingTooltip tooltipRole="label" placement={tooltipPlacement}>
      <PingTooltipTrigger
        className={classes}
        onClick={isDisabled ? () => {} : onClick}
      >
        <PingMaterialIcon
          className="SiteHeaderActionButton__Icon"
          iconName={iconName}
        />
        {isNumber(notificationCount) && notificationCount > 0 && (
          <div className="SiteHeaderActionButton__NotificationCount">
            {notificationCount < 10 ? notificationCount : "9+"}
          </div>
        )}
      </PingTooltipTrigger>
      {!isActive && !isDisabled && (
        <PingTooltipContent>{title}</PingTooltipContent>
      )}
      {!isActive && isDisabled && (
        <PingTooltipContent>{disabledTooltip}</PingTooltipContent>
      )}
    </PingTooltip>
  );
};
