import { Helmet } from "react-helmet-async";
import cx from "classnames";

import { PoweredByPing } from "../components/nav/PingLogo";

import "./BaseLayout.scss";

type BaseLayoutProps = {
  title?: string;
  shouldShowPoweredByPing?: boolean;
  children?: React.ReactNode;
  className?: string;
};

const PRODUCT_NAME = "Ping Data Intelligence";

export const BaseLayout = ({
  title,
  shouldShowPoweredByPing = true,
  children,
  className,
}: BaseLayoutProps) => {
  const fullTitle = title ? `${title} · ${PRODUCT_NAME}` : PRODUCT_NAME;

  const appliedClasses = cx("BaseLayout", className);

  return (
    <>
      <Helmet>
        <title>{fullTitle}</title>
      </Helmet>
      <div className={appliedClasses}>{children}</div>
      {shouldShowPoweredByPing && <PoweredByPing />}
    </>
  );
};
