import { Check, CircleX } from "lucide-react";
import { FC, useCallback } from "react";

import { useAppSelector } from "src/utils/redux";
import { SeverityIndicator } from "@repo/ping-react-js";

import { PVSectionSubHeading } from "features/submission-dashboard/PVHeadings";
import { PVSubmissionSummary } from "features/submission-dashboard/PVSubmissionSummary";
import { PRSubmissionDetailsToolbar } from "features/submission-dashboard/PRSubmissionDetailsToolbar";
import {
  HeadingSchemaOptions,
  PingRichTextInputField,
} from "features/submission-dashboard/PingRichTextInputField";
import { PVInsuredInformationForm } from "features/submission-dashboard/PVInsuredInformationForm";
import { PVBrokerInformationGrid } from "features/submission-dashboard/PVBrokerInformationGrid";
import { PingVisionSubmissionAttachments } from "features/submission-dashboard/PingVisionSubmissionAttachment";
import { PVSubmissionPill } from "features/submission-dashboard/PVSubmissionPill";
import PVNotes from "features/submission-dashboard/PVNotes";
import { PVOverviewGrid } from "features/submission-dashboard/PVOverviewGrid";
import {
  PVScrollSpyProvider,
  PVScrollSpySection,
  PVScrollSpySections,
  PVScrollSpySectionWrapper,
  PVScrollSpyTabBar,
} from "features/submission-dashboard/PVScrollSpy";
import { useUpdateSubmissionTriageMutation } from "services/pvSlice";

import { findSubmissionTitle, getFullTeamName } from "utils/submission";
import { SovDataType } from "ts-types/DataTypes";
import { ActivityItemType } from "ts-types/ApiTypes";
import { useSubmissionHistory } from "./useSubmissionHistory";
import PingUploadWrapper from "features/PingUploadWrapper";

import "./PingVisionSubmissionDetails.scss";
import { PingVisionSubmissionActivityItem } from "./PingVisionSubmissionActivityItem";

type PingVisionSubmissionDetailsProps = {
  selectedItem: SovDataType;
};

const HEADING_SCHEMA_OPTIONS: HeadingSchemaOptions = {
  type: "heading",
  level: 2,
};

export const PingVisionSubmissionDetails: FC<
  PingVisionSubmissionDetailsProps
> = ({ selectedItem }) => {
  const submissionHistory: ActivityItemType[] = useAppSelector(
    (state) => state.inbox.submissionHistory
  );

  const mergedHistory = useSubmissionHistory({ submissionHistory });

  const [updateSubmission] = useUpdateSubmissionTriageMutation();

  const onTitleBlur = useCallback(
    (newContent: string) => {
      updateSubmission({
        id: selectedItem.id,
        data: {
          insured_name: newContent,
        },
      });
    },
    [selectedItem, updateSubmission]
  );

  const readiness = selectedItem?.data_readiness_notes?.filter(
    (_) => _.severity !== "debug"
  );
  if (
    selectedItem.data_readiness_notes?.length &&
    selectedItem.data_readiness_notes?.length > 0 &&
    readiness?.length === 0
  ) {
    readiness.push({
      severity: "info",
      reason: "No data readiness notes available",
      value: 1.0,
    });
  }

  const groupedNotes = [];
  let currentCaption = null;
  for (const note of readiness ?? []) {
    const currentGroupCaption = note.sheet || "General";
    if (!currentCaption || currentCaption.caption !== currentGroupCaption) {
      currentCaption = { caption: currentGroupCaption, notes: [] };
      groupedNotes.push(currentCaption);
    }
    currentCaption.notes.push(note);
  }

  const getRuleResultDisplay = (result: string) => {
    const size = 18;
    switch (result) {
      case "pass":
        return <Check size={size} color="green" />;
      case "fail":
        return <CircleX size={size} color="red" />;
      case "warning":
        return <CircleX size={size} color="yellow" />;
      case "exception":
        return <CircleX size={size} color="brightred" />;
      default:
        return <CircleX size={size} color="gray" />;
    }
  };

  return (
    <PVScrollSpyProvider offsetPx={165}>
      <div className="PingVisionSubmissionDetails">
        <header className="PingVisionSubmissionDetails__Header">
          <PRSubmissionDetailsToolbar selectedItem={selectedItem} />
          <div className="PingVisionSubmissionDetails__Header__Metadata">
            <div className="PingVisionSubmissionDetails__Header__Metadata__Item">
              {selectedItem.id}
            </div>
            <div className="PingVisionSubmissionDetails__Header__Metadata__Item">
              {getFullTeamName(selectedItem)}
            </div>
          </div>
          <div className="PingVisionSubmissionDetails__Header__InsuredEditorContainer">
            <PingRichTextInputField
              key={`${selectedItem.id}-${selectedItem.insured_name}`}
              schemaOptions={HEADING_SCHEMA_OPTIONS}
              initialContent={findSubmissionTitle(selectedItem) || ""}
              onBlur={onTitleBlur}
              className="PingVisionSubmissionDetails__Header__InsuredEditorContainer__Editor"
            />
            <PVSubmissionPill sov={selectedItem} />
          </div>
          <PVScrollSpyTabBar />
        </header>

        <PVScrollSpySections className="PingVisionSubmissionDetails__Content">
          <PVScrollSpySectionWrapper title="Summary">
            <PVSubmissionSummary selectedItem={selectedItem} />
          </PVScrollSpySectionWrapper>

          <PVScrollSpySectionWrapper title="Basic Overview">
            <PVOverviewGrid selectedItem={selectedItem} />
          </PVScrollSpySectionWrapper>

          <PVScrollSpySection title="Attachments">
            <PingUploadWrapper>
              <PingVisionSubmissionAttachments submission={selectedItem} />
            </PingUploadWrapper>
          </PVScrollSpySection>

          {groupedNotes.length > 0 && (
            <PVScrollSpySection title="Data Readiness">
              <ul className="PingVisionSubmissionDetails__DataReadiness__Group">
                {groupedNotes.map((grouped_note, index2) => (
                  <li key={index2}>
                    <PVSectionSubHeading>
                      {grouped_note.caption}
                    </PVSectionSubHeading>
                    <ul className="PingVisionSubmissionDetails__DataReadiness__Subgroup">
                      {grouped_note.notes.map((note, index) => (
                        <li key={index}>
                          {" "}
                          <SeverityIndicator readinessScore={note?.value} />
                          <span>{note.reason}</span>
                        </li>
                      ))}
                    </ul>
                  </li>
                ))}
              </ul>
            </PVScrollSpySection>
          )}

          {selectedItem.triage_rule_results?.length > 0 && (
            <PVScrollSpySection title="Submission Triage">
              <table>
                {selectedItem.triage_rule_results.map((result, index) => (
                  <tr key={index}>
                    <td>{getRuleResultDisplay(result.result)}</td>
                    <td>{result.name}</td>
                    {/* <td>{result.value}</td> */}
                  </tr>
                ))}
              </table>
            </PVScrollSpySection>
          )}

          <PVScrollSpySectionWrapper title="Insured Information">
            <PVInsuredInformationForm selectedItem={selectedItem} />
          </PVScrollSpySectionWrapper>

          <PVScrollSpySectionWrapper title="Broker Information">
            <PVBrokerInformationGrid selectedItem={selectedItem} />
          </PVScrollSpySectionWrapper>

          <PVScrollSpySection title="Activity">
            <ul className="PingVisionSubmissionDetails__Activity__List">
              {mergedHistory?.map((historyItem, i) => {
                return (
                  <li key={i}>
                    <PingVisionSubmissionActivityItem
                      historyItem={historyItem}
                    />
                  </li>
                );
              })}
            </ul>
            <PVNotes selectedItem={selectedItem} />
          </PVScrollSpySection>
        </PVScrollSpySections>
      </div>
    </PVScrollSpyProvider>
  );
};
