import { PanelResizeHandle } from "react-resizable-panels";
import cx from "classnames";

import "./PingPanelResizeHandle.scss";

type PingPanelResizeHandleProps = {
  orientation: "horizontal" | "vertical";
  width?: "normal" | "slim";
};

/**
 * A resize handle for the resizable areas we use across the application.
 *
 * This is just a wrapper around the `PanelResizeHandle` component from the
 * `react-resizable-panels` package. We use this to apply some custom styling to
 * our resize handles.
 */
export const PingPanelResizeHandle = ({
  orientation,
  width = "normal",
}: PingPanelResizeHandleProps) => {
  const appliedClasses = cx("PingPanelResizeHandle", {
    "PingPanelResizeHandle--Horizontal": orientation === "horizontal",
    "PingPanelResizeHandle--Vertical": orientation === "vertical",
    "PingPanelResizeHandle--Normal": width === "normal",
    "PingPanelResizeHandle--Slim": width === "slim",
  });
  return (
    <PanelResizeHandle>
      <div className={appliedClasses} />
    </PanelResizeHandle>
  );
};
