import React from "react";
import ReactDOM from "react-dom/client";
import Root from "./components/Root.tsx";
import "./index.scss";

ReactDOM.createRoot(document.getElementById("app")!).render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
