import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EnvType } from "ts-types/DataTypes";
import { getApiBaseUrl } from "../utils";
import { api } from "services/pvSlice";
import slugify from "slugify";
import type {
  NavigationResponse,
  SettingsResponseType,
} from "ts-types/ApiTypes";
import type { UserType } from "ts-types/DataTypes";

type QueryParamsType = Record<
  string,
  string | Record<string, number> | ((id: number) => Record<string, number>)
>;

const transformJson = (input) => {
  const result: QueryParamsType = {};

  const processItem = (item, parentName = "") => {
    if (item.slug && item.filter) {
      const key = parentName
        ? `${slugify(parentName)}-${item.slug}`
        : item.slug;
      result[key] = item.filter;
    }

    if (item.items && Array.isArray(item.items)) {
      const name = item.name || parentName;
      item.items.forEach((subItem) => processItem(subItem, name));
      result[`${slugify(name)}-my-issues`] = (claimed_by_id: number) => {
        return { claimed_by_id };
      };
    }
  };

  input.views.forEach((item) => processItem(item));

  return result;
};

type PingSettingsInitialState = {
  /* */
  baseUrl: string;
  /* */
  env: string;
  /* */
  isPingEmployee: boolean;
  /* */
  envData: EnvType | null;
  /* */
  nav: NavigationResponse | null;
  /* */
  settings: SettingsResponseType | null;
  /* */
  isCommandMenuOpened: boolean;
  /* */
  selectedSubmissions: string[];
  /**/
  userIdToUserMap: Record<number, UserType>;
  /* */
  navToQueryParams: QueryParamsType;
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    baseUrl: getApiBaseUrl(),
    env: import.meta.env.VITE_APP_ENV,
    isPingEmployee: false,
    envData: null,
    nav: null,
    settings: null,
    isCommandMenuOpened: false,
    selectedSubmissions: [],
    userIdToUserMap: {},
    navToQueryParams: {},
  } as PingSettingsInitialState,
  reducers: {
    setIsEmployee(state, actions: PayloadAction<boolean>) {
      state.isPingEmployee = actions.payload;
    },
    setIsCommandMenuOpened(state, actions: PayloadAction<boolean>) {
      state.isCommandMenuOpened = actions.payload;
    },
    setSelectedSubmissions(state, actions: PayloadAction<string[]>) {
      state.selectedSubmissions = actions.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getSettings.matchFulfilled,
      (state, action: PayloadAction<SettingsResponseType>) => {
        state.settings = action.payload;
        state.userIdToUserMap = action.payload.users?.reduce((acc, curr) => {
          acc[curr.id] = curr;
          return acc;
        }, {});
      },
    );
    builder.addMatcher(
      api.endpoints.getNav.matchFulfilled,
      (state, action: PayloadAction<NavigationResponse>) => {
        state.nav = action.payload;
        state.navToQueryParams = transformJson(action.payload);
      },
    );
    builder.addMatcher(
      api.endpoints.getEnvironment.matchFulfilled,
      (state, action: PayloadAction<EnvType>) => {
        state.envData = action.payload;
      },
    );
  },
});

const { actions, reducer } = settingsSlice;

export const { setSelectedSubmissions, setIsCommandMenuOpened, setIsEmployee } =
  actions;

export default reducer;
