export const getApiBaseUrl = () => {
  if (import.meta.env.VITE_APP_ENV === "local") {
    const port = import.meta.env.VITE_APP_LOCAL_API_PORT || "80";
    return `http://local.pingintel.com:${port}/`;
  }

  if (import.meta.env.VITE_APP_ENV === "prd") {
    return "https://radar.pingintel.com/";
  } else if (import.meta.env.VITE_APP_ENV === "prdeu") {
    new Error("not supported!");
  } else if (import.meta.env.VITE_APP_ENV === "stg") {
    return "https://radar.staging.pingintel.com/";
  } else {
    return "https://radar.dev.pingintel.com/";
  }
};
