export const initiateDownload = (
  downloadUrl: string,
  accessToken: string,
  filename: string
) => {
  console.assert(accessToken, "accessToken is required");
  try {
    fetch(downloadUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((res: any) => res.blob())
      .then((blob: any) => {
        if (blob?.size > 1_000) {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          alert("File does not exist.");
        }
      });
  } catch (error) {
    console.error("Download error:", error);
  }
};


export const getAwsAccountIdByProduct = (
  product: "sov-scrubber" | "pingvision"
) => {
  if (product === "sov-scrubber") {
    return "178202060762";
  } else if (product === "pingvision") {
    return "891377000753";
  }
};

export const getAwsConsoleUrl = (
  destinationUrl: string,
  product: "sov-scrubber" | "pingvision" = "sov-scrubber",
  roleName: "S3-ReadOnly" | "Admin" = "S3-ReadOnly"
) => {
  const url = "https://pingintel.awsapps.com/start/#/console";
  const params = {
    account_id: getAwsAccountIdByProduct(product),
    role_name: roleName,
    destination: destinationUrl,
  };
  // url = f'{url}?{urllib.parse.urlencode(params, quote_via=urllib.parse.quote, safe="")}'
  return `${url}?${new URLSearchParams(params).toString()}`;
};