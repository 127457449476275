/*global google*/
import {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { GoogleMapsContext } from "@vis.gl/react-google-maps";
import type { Ref } from "react";

type RectangleEventProps = {
  onClick?: (e: google.maps.MapMouseEvent) => void;
  onDrag?: (e: google.maps.MapMouseEvent) => void;
  onDragStart?: (e: google.maps.MapMouseEvent) => void;
  onDragEnd?: (e: google.maps.MapMouseEvent) => void;
  onMouseOver?: (e: google.maps.MapMouseEvent) => void;
  onMouseOut?: (e: google.maps.MapMouseEvent) => void;
  onBoundsChanged?: (bounds: google.maps.LatLngBounds) => void; // Updated this line
};

type RectangleCustomProps = {
  // Add any custom properties here if needed
};

export type RectangleProps = google.maps.RectangleOptions &
  RectangleEventProps &
  RectangleCustomProps;

export type RectangleRef = Ref<google.maps.Rectangle | null>;

function useRectangle(props: RectangleProps) {
  const {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    onBoundsChanged,
    ...rectangleOptions
  } = props;

  const callbacks = useRef<Record<string, (e: unknown) => void>>({});
  Object.assign(callbacks.current, {
    onClick,
    onDrag,
    onDragStart,
    onDragEnd,
    onMouseOver,
    onMouseOut,
    onBoundsChanged,
  });

  const rectangle = useRef(new google.maps.Rectangle()).current;

  useMemo(() => {
    rectangle.setOptions(rectangleOptions);
  }, [rectangle, rectangleOptions]);

  const map = useContext(GoogleMapsContext)?.map;

  useEffect(() => {
    if (!map) {
      if (map === undefined)
        console.error("<Rectangle> has to be inside a Map component.");
      return;
    }

    rectangle.setMap(map);

    return () => {
      rectangle.setMap(null);
    };
  }, [map, rectangle]);

  useEffect(() => {
    if (!rectangle) return;

    const gme = google.maps.event;
    [
      ["click", "onClick"],
      ["drag", "onDrag"],
      ["dragstart", "onDragStart"],
      ["dragend", "onDragEnd"],
      ["mouseover", "onMouseOver"],
      ["mouseout", "onMouseOut"],
    ].forEach(([eventName, eventCallback]) => {
      gme.addListener(rectangle, eventName, (e: google.maps.MapMouseEvent) => {
        const callback = callbacks.current[eventCallback];
        if (callback) callback(e);
      });
    });

    // Add separate listener for bounds_changed event
    gme.addListener(rectangle, "bounds_changed", () => {
      const callback = callbacks.current.onBoundsChanged;
      if (callback) callback(rectangle.getBounds());
    });

    return () => {
      gme.clearInstanceListeners(rectangle);
    };
  }, [rectangle]);

  return rectangle;
}

export const PingGoogleRectangle = forwardRef(
  (props: RectangleProps, ref: RectangleRef) => {
    const rectangle = useRectangle(props);

    useImperativeHandle(ref, () => rectangle, [rectangle]);

    return null;
  }
);
