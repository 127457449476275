import { useCallback, useMemo } from "react";
import { History } from "history";

import {
  PingSearchQueryBuilderField,
  TEXT_SEGMENT_KEY,
} from "./PingSearchQueryBuilder";
import { useQuery, pushQueryParameters } from "@repo/ping-react-js";

const isKeyInFields = (key: string, fields: PingSearchQueryBuilderField[]) => {
  return fields.find((field) => key.startsWith(field.fieldName));
};

export const useSearchQueryBuilder = (
  fields: PingSearchQueryBuilderField[],
  history: History,
  textSegmentParamName: string
) => {
  const searchParams = useQuery();
  // Find key-value pairs from search string that are valid according to our
  // fields list + the search field.
  const searchValues = useMemo(() => {
    const values = {};

    const searchText = searchParams.get(textSegmentParamName)?.trim();
    if (searchText && searchText.length > 0) {
      values[TEXT_SEGMENT_KEY] = searchText;
    }

    for (const [key, value] of searchParams) {
      if (key === textSegmentParamName) {
        continue;
      }

      if (isKeyInFields(key, fields)) {
        values[key] = value;
      }
    }

    return values;
  }, [fields, searchParams, textSegmentParamName]);

  // This generates a key-value pair object with all keys belonging to our
  // fields set to null. We use this to clear out URL search parameters.
  const getNulledParams = useCallback(() => {
    const params = { [textSegmentParamName]: null };

    for (const [key] of searchParams) {
      if (key === TEXT_SEGMENT_KEY) {
        continue;
      }

      if (isKeyInFields(key, fields)) {
        params[key] = null;
      }
    }

    return params;
  }, [fields, searchParams, textSegmentParamName]);

  // Takes key-value pairs and updates the search string, making sure to remove
  // any keys that are not in the fields list.
  const setSearchValues = useCallback(
    (newValues: Record<string, string>) => {
      const params = {
        [textSegmentParamName]: newValues[TEXT_SEGMENT_KEY] || null,
      };

      for (const [key, value] of Object.entries(newValues)) {
        if (isKeyInFields(key, fields)) {
          params[key] = value;
        }
      }

      // This will clear the search string of any existing search values that
      // pertain to our fields.
      const nulledParams = getNulledParams();

      pushQueryParameters(searchParams, history, {
        ...nulledParams,
        ...params,
      });
    },
    [fields, history, searchParams, getNulledParams, textSegmentParamName]
  );

  const clearSearchValues = useCallback(() => {
    const params = getNulledParams();

    pushQueryParameters(searchParams, history, {
      ...params,
    });
  }, [history, searchParams, getNulledParams]);

  return { searchValues, setSearchValues, clearSearchValues };
};
