import { FieldValues, UseFormSetError } from "react-hook-form";

function setServerErrors(
  errors: Record<string, string[]>,
  setError: UseFormSetError<FieldValues>
) {
  if (!errors || Object.keys(errors).length === 0) {
    setError("root.serverError", {
      type: "custom",
      message: "The server returned an unknown error",
    });
    return;
  }

  const transformedErrors = Object.fromEntries(
    Object.entries(errors).map(([key, value]) => [
      key,
      Array.isArray(value) ? value[0] : value,
    ])
  );

  Object.entries(transformedErrors).forEach(([key, message]) => {
    setError(key, {
      type: "manual",
      message: message as string,
    });
  });
}

export { setServerErrors };
