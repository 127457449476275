import { SovDataType } from "ts-types/DataTypes";

/**
 * Returns a possible title for the submission. If the submission has an
 * attachment with a document type of "sov", the filename of that attachment is
 * the title. Otherwise, we return a string with the company and division of the
 * submission.
 *
 * In the future, we'll use the insured name as the title by default and fall
 * back to the filename. But we don't have that data from the API yet.
 */
export const findSubmissionTitle = (submission: SovDataType) => {
  if (!submission) {
    return null;
  }

  if (submission.insured_name) {
    return submission.insured_name;
  }

  const sovAttachment = submission.documents.find(
    (attachment) => attachment.document_type.toLowerCase() === "sov"
  );

  return (
    sovAttachment?.filename ??
    `${submission.company__short_name} - ${submission.division__short_name}`
  );
};

export const getHumanFriendlyDataReadinessScore = (score?: number | null) => {
  let uwRuleOverallResult = null;
  if (score === undefined || score === null) {
    uwRuleOverallResult = {
      color: "gray",
      level: "Unknown",
      label: "Unknown",
      icon: "SquareDashed",
    };
  } else if (score >= 1.0) {
    uwRuleOverallResult = {
      color: "green",
      level: "High",
      label: "Excellent",
      icon: "Grid2x2Check",
    };
  } else if (score >= 0.9) {
    uwRuleOverallResult = {
      color: "green",
      level: "High",
      label: "Good",
      icon: "Grid2x2Check",
    };
  } else if (score >= 0.8) {
    uwRuleOverallResult = {
      color: "#da8920",
      level: "Medium",
      label: "Consider Review",
      icon: "Grid2x2X",
    };
  } else {
    uwRuleOverallResult = {
      color: "red",
      level: "Low",
      label: "NEEDS REVIEW",
      icon: "Grid2x2X",
    };
  }
  return uwRuleOverallResult;
};

export const getHumanFriendlyTriageScore = (
  triageRulesOverallResult?: string | null
) => {
  let triageResult = null;
  if (!triageRulesOverallResult) {
    triageResult = {
      color: "gray",
      level: "Unknown",
      label: "Unknown",
      icon: "SquareDashed",
    };
  } else if (triageRulesOverallResult === "D") {
    triageResult = {
      color: "red",
      level: "Declined",
      label: "Declined",
      icon: "Ban",
    };
  } else if (triageRulesOverallResult === "A") {
    triageResult = {
      color: "green",
      level: "Approved",
      label: "Approved",
      icon: "Check",
    };
  } else if (triageRulesOverallResult === "C") {
    triageResult = {
      color: "#da8920",
      level: "Consider",
      label: "Consider",
      icon: "PillBottle",
    };
  }

  return triageResult;
};

export const getFullTeamName = (selectedItem: SovDataType) => {
  if (!selectedItem) {
    return null;
  }
  if (selectedItem.company__short_name === selectedItem.division__short_name)
    return `${selectedItem.company__short_name} - ${selectedItem.team__name}`;
  else
    return `${selectedItem.company__short_name} - ${selectedItem.division__short_name} - ${selectedItem.team__name}`;
};
