import { z } from "zod";

/**
 * Creates a Zod schema preprocessor that converts empty strings to null.
 * Useful for form handling where empty strings should be treated as null
 * values.
 */
const emptyStringToNull = () =>
  z.preprocess((v) => (v === "" ? null : v), z.string().nullable());

/**
 * Creates a Zod schema for a required string that must be non-empty after
 * trimming.
 */
const requiredString = (message: string = "This field must not be empty") =>
  z.string().trim().min(1, { message });

export { emptyStringToNull, requiredString };
