import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { baseQueryWithZodValidation } from "@repo/ping-react-js";

import { getApiBaseUrl } from "../utils";
import { PING_VISION_DEFAULT_GRID_PAGE_SIZE } from "constants/ApiConstants";
import type { RootState } from "services/store";
import {
  type NavigationResponse,
  type ActivityItemType,
  type EmailCorrespondenceResponse,
  emailCorrespondenceResponseSchema,
} from "ts-types/ApiTypes";
import {
  SovDataTypePaginatedResponse,
  sovDataTypePaginatedResponseSchema,
} from "ts-types/DataTypes";

export const api = createApi({
  baseQuery: baseQueryWithZodValidation(
    fetchBaseQuery({
      baseUrl: getApiBaseUrl(),
      prepareHeaders: (headers, { getState, endpoint }) => {
        if (endpoint !== "uploadDocument") {
          headers.set("Content-Type", "application/json");
        }
        const state = getState() as RootState;
        const accessToken = state.auth.accessToken;
        if (accessToken && !headers.has("Authorization")) {
          headers.set("Authorization", `Bearer ${accessToken}`);
        }

        return headers;
      },
    }),
  ),

  tagTypes: ["PVSubmissionsList", "PVNotes"],

  endpoints: (build) => ({
    getEnvironment: build.query<any, void>({
      query: () => ({
        url: `api/v1/environment`,
        method: "GET",
      }),
    }),
    getSubmissionHistory: build.query<
      ActivityItemType[],
      { id: string; realTimeSubscriptions: Record<string, number[]> }
    >({
      query: ({ id }) => ({
        url: `api/v1/submission/${id}/history`,
        method: "GET",
      }),
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState },
      ) {
        const state = getState() as RootState;
        const accessToken = state.auth?.accessToken;
        const ENABLE_CHANNELS = state.settings.envData?.ENABLE_CHANNELS;
        const subscriptions = arg.realTimeSubscriptions?.teams;

        if (!ENABLE_CHANNELS) {
          console.info("Channels are disabled");
          return;
        }

        if (!accessToken) {
          console.error("No access token available");
          return;
        }

        if (!subscriptions.length) {
          console.error("No subscriptions available");
          return;
        }

        const searchParams = new URLSearchParams();
        searchParams.append("token", accessToken);
        if (arg.realTimeSubscriptions?.teams) {
          searchParams.append(
            "team_ids",
            arg.realTimeSubscriptions?.teams.join(","),
          );
        }

        const ws = new WebSocket(
          `${import.meta.env.VITE_APP_WEBSOCKETS_CHANNEL}?${searchParams}`,
        );

        ws.onopen = () => {
          ws.send(JSON.stringify({ type: "authenticate", token: accessToken }));
        };

        try {
          await cacheDataLoaded;

          ws.onmessage = (event) => {
            try {
              const message = JSON.parse(event.data);
              console.info("WebSocket message:", message);

              if (message.type === "submission.history.list") {
                const { data } = message.data;
                updateCachedData((draft) => {
                  return data;
                });
              }
            } catch (parseError) {
              console.error("Error parsing WebSocket message:", parseError);
            }
          };

          ws.onerror = (error) => {
            console.error("WebSocket error:", error);
          };
        } catch (error) {
          console.error("Error in cache handling or WebSocket setup:", error);
        }

        await cacheEntryRemoved;
        ws.close();
      },
      providesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    getSubmissions: build.query<
      SovDataTypePaginatedResponse,
      {
        fields?: string[];
        realTimeSubscriptions: Record<string, number[]>;
        search?: string | null;
        advancedSearchFields?: Record<string, string> | null;
        cursorId?: string | null;
        orgShortName?: string | null;
      }
    >({
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState },
      ) {
        const state = getState() as RootState;
        const accessToken = state.auth?.accessToken;
        const ENABLE_CHANNELS = state.settings.envData?.ENABLE_CHANNELS;
        const subscriptions = arg.realTimeSubscriptions?.teams;
        const submission_statuses = state.settings?.settings?.submission_status;

        if (!ENABLE_CHANNELS) {
          console.info("Channels are disabled");
          return;
        }

        if (!accessToken) {
          console.error("No access token available");
          return;
        }

        if (!subscriptions.length) {
          console.error("No subscriptions available");
          return;
        }

        const searchParams = new URLSearchParams();
        searchParams.append("token", accessToken);
        if (arg.realTimeSubscriptions?.teams) {
          searchParams.append(
            "team_ids",
            arg.realTimeSubscriptions?.teams.join(","),
          );
        }

        const ws = new WebSocket(
          `${import.meta.env.VITE_APP_WEBSOCKETS_CHANNEL}?${searchParams}`,
        );

        ws.onopen = () => {
          ws.send(JSON.stringify({ type: "authenticate", token: accessToken }));
        };

        try {
          await cacheDataLoaded;

          ws.onmessage = (event) => {
            try {
              const message = JSON.parse(event.data);
              console.info("WebSocket message:", message);

              if (message.type === "submission.update") {
                let { changed_fields } = message.data;
                const { id, changed_documents } = message.data;
                // DO NOT CHANGE: the backend does not send the client workflow_status__name, so we needs to queried and appended to the
                // redux state manually. This avoids a query inside the re-saved hook.
                if (changed_fields?.workflow_status_id) {
                  const workflow_status__name = submission_statuses?.find(
                    (s) => s.id === changed_fields.workflow_status_id,
                  )?.name;
                  changed_fields = { ...changed_fields, workflow_status__name };
                }

                updateCachedData((draft) => {
                  const itemIndex = draft.results.findIndex(
                    (item) => item.id === id,
                  );
                  if (itemIndex !== -1) {
                    draft.results[itemIndex] = {
                      ...draft.results[itemIndex],
                      ...changed_fields,
                    };
                    if (changed_documents?.length) {
                      changed_documents.forEach((newDocument) => {
                        const docIndex = draft.results[
                          itemIndex
                        ].documents.findIndex(
                          (doc) => doc.id === newDocument.id,
                        );
                        if (docIndex !== -1) {
                          draft.results[itemIndex].documents[docIndex] =
                            newDocument;
                        } else {
                          draft.results[itemIndex].documents.push(newDocument);
                        }
                      });
                    }
                  }
                });
              } else if (message.type === "submission.create") {
                const newSubmission = message.data;

                updateCachedData((draft) => {
                  if (
                    !draft.results.find((item) => item.id === newSubmission.id)
                  ) {
                    draft.results.unshift({ ...newSubmission.data });
                    draft.total_count += 1;
                  }
                });
              }
            } catch (parseError) {
              console.error("Error parsing WebSocket message:", parseError);
            }
          };

          ws.onerror = (error) => {
            console.error("WebSocket error:", error);
          };
        } catch (error) {
          console.error("Error in cache handling or WebSocket setup:", error);
        }

        await cacheEntryRemoved;
        ws.close();
      },

      query: ({ fields, search, advancedSearchFields }) => {
        const searchParams = new URLSearchParams();

        searchParams.append(
          "page_size",
          PING_VISION_DEFAULT_GRID_PAGE_SIZE.toString(),
        );

        searchParams.append("fields", fields?.join(",") || "");

        if (search) {
          searchParams.append("search", search);
        }

        if (advancedSearchFields) {
          Object.entries(advancedSearchFields).forEach(([key, value]) => {
            searchParams.append(key, value);
          });
        }

        return {
          url: `api/v1/submission?${searchParams.toString()}`,
          method: "GET",
        };
      },
      providesTags: () => [{ type: "PVSubmissionsList", id: "LIST" }],
      extraOptions: {
        dataSchema: sovDataTypePaginatedResponseSchema,
      },
    }),
    uploadDocument: build.mutation({
      query: ({ accessToken, id, file }) => ({
        url: `api/v1/submission/${id}/document`,
        method: "POST",
        body: file,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    bulkUpdateSubmission: build.mutation({
      query: ({ ids, changes }) => ({
        url: `api/v1/submission/bulkupdate`,
        method: "POST",
        body: { ids, changes },
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    changeSubmissionTriageStatus: build.mutation<
      any,
      { id: string; status: string }
    >({
      query: ({ id, status }) => ({
        url: `api/v1/submission/${id}/change_status/`,
        method: "PATCH",
        body: { workflow_status_id: status },
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    markSubmissionAsDuplicate: build.mutation<
      any,
      { id: string; duplicate_of_submission_id: string }
    >({
      query: ({ id, duplicate_of_submission_id }) => ({
        url: `api/v1/submission/${id}/mark_as_duplicate/`,
        method: "PATCH",
        body: { duplicate_of_submission_id: duplicate_of_submission_id },
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }, "PVNotes"],
    }),
    // used for rename, document type change, and archiving.
    updateSubmissionDocument: build.mutation<
      any,
      { id: string; filename: string; data: Record<string, any> }
    >({
      query: ({ id, filename, data }) => ({
        url: `api/v1/submission/${id}/document/${filename}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    // used for rename, document type change, and archiving.
    parseSovFile: build.mutation<any, { id: string; filename: string }>({
      query: ({ id, filename }) => ({
        url: `api/v1/submission/${id}/document/${filename}/sovfixer-parse`,
        method: "POST",
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),
    updateSubmissionTriage: build.mutation<
      any,
      { id: string; data: Record<string, any> }
    >({
      query: ({ id, data }) => ({
        url: `api/v1/submission/${id}/`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [{ type: "PVSubmissionsList", id: "LIST" }],
    }),

    getNav: build.query<NavigationResponse, any>({
      query: () => ({
        url: `api/v1/nav`,
        method: "GET",
      }),
    }),
    getSettings: build.query<any, any>({
      query: () => ({
        url: `api/v1/settings`,
        method: "GET",
      }),
    }),
    getEmailCorrespondence: build.query<
      EmailCorrespondenceResponse,
      { sovid: string }
    >({
      query: ({ sovid }) => ({
        url: `api/v1/submission/${sovid}/correspondence`,
      }),
      extraOptions: {
        dataSchema: emailCorrespondenceResponseSchema,
      },
    }),

    createNote: build.mutation({
      query: ({ id, text }) => ({
        url: `api/v1/submission/note/`,
        method: "POST",
        body: { submission: id, text: text },
      }),
      invalidatesTags: ["PVNotes"],
    }),
  }),
});

export const {
  useCreateNoteMutation,
  useBulkUpdateSubmissionMutation,
  useGetNavQuery,
  useUploadDocumentMutation,
  useGetSubmissionHistoryQuery,
  useGetSubmissionsQuery,
  useChangeSubmissionTriageStatusMutation,
  useMarkSubmissionAsDuplicateMutation,
  useUpdateSubmissionTriageMutation,
  useGetEnvironmentQuery,
  useGetSettingsQuery,
  useUpdateSubmissionDocumentMutation,
  useParseSovFileMutation,
  useGetEmailCorrespondenceQuery,
} = api;
