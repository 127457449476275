import { HelmetProvider } from "react-helmet-async";
import {
  withAuthInfo,
  AuthProvider,
  WithAuthInfoProps,
} from "@propelauth/react";

import { AuthContext } from "../utils/context";

const PropAuthWrapper = ({
  isLoggedIn,
  accessToken,
  user,
  isImpersonating,
  accessTokenExpiresAtSeconds,
  userClass,
  orgHelper,
  impersonatorUserId,
  accessHelper,
  VITE_APP_AUTH_URL,
  ...restProps
}: WithAuthInfoProps & {
  VITE_APP_AUTH_URL?: string;
  children?: React.ReactNode;
}) => {
  // cannot use useQuery here because it's before the router stuff is initialized.
  const client = new URL(window.location.href).searchParams.get("client");

  const getSamlUrl = (client: string | null): string => {
    return client
      ? `${VITE_APP_AUTH_URL}/api/fe/v3/login/saml?org_name=${client}&rt=${btoa(window.location.href)}`
      : `${VITE_APP_AUTH_URL}/api/fe/v3/login/saml?rt=${btoa(window.location.href)}`;
  };

  if (!isLoggedIn) {
    window.location.href = getSamlUrl(client);
  }

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        accessToken: accessToken,
        ssoUser: user,
        isImpersonating: isImpersonating,
        accessTokenExpiresAtSeconds: accessTokenExpiresAtSeconds,
        userClass: userClass,
        orgHelper: orgHelper,
        impersonatorUserId: impersonatorUserId,
        accessHelper: accessHelper,
      }}
    >
      {restProps?.children}
    </AuthContext.Provider>
  );
};

const WrappedRootWrapper = withAuthInfo(PropAuthWrapper);

export const RootWrapper = ({
  VITE_APP_AUTH_URL,
  children,
}: {
  VITE_APP_AUTH_URL: string;
  children: React.ReactNode;
}) => {
  if (!VITE_APP_AUTH_URL) {
    return <div>You need to VITE_APP_AUTH_URL in your .env file.</div>;
  }

  return (
    <AuthProvider authUrl={VITE_APP_AUTH_URL}>
      <HelmetProvider>
        <WrappedRootWrapper VITE_APP_AUTH_URL={VITE_APP_AUTH_URL}>
          {children}
        </WrappedRootWrapper>
      </HelmetProvider>
    </AuthProvider>
  );
};
