import { useAppSelector } from "utils/redux";
import { useParams } from "react-router-dom";

export const useAuth = () => {
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  return { accessToken };
};

export const usePingId = (): string | null => {
  const { pingId } = useParams<{
    pingId: string;
  }>();

  // pingIds are guaranteed to start with "p-"
  return !pingId?.startsWith("p-") ? null : pingId;
};

export const useSlug = (): string | null => {
  const { slug: slugFromUrl } = useParams<{
    slug: string;
  }>();

  const slugFromState = useAppSelector((state) => state.inbox.slug);

  return slugFromUrl || slugFromState;
};

export const useTeamId = () => {
  const { teamId: teamIdFromUrl } = useParams<{
    teamId: string;
  }>();

  const teamIdFromState = useAppSelector((state) => state.inbox.teamId);

  return teamIdFromUrl || teamIdFromState;
};
