import { FC } from "react";
import cx from "classnames";

import "./PVEmptyPanelMessage.scss";

type PVEmptyPanelMessageProps = {
  message: string;
  className?: string;
};

export const PVEmptyPanelMessage: FC<PVEmptyPanelMessageProps> = ({
  message,
  className,
}) => {
  const appliedClasses = cx("PVEmptyPanelMessage", className);
  return (
    <div className={appliedClasses}>
      <p>{message}</p>
    </div>
  );
};
