import { initiateDownload } from "../../utils/download-utils";

const SSOALink = ({
  filename,
  downloadUrl,
  accessToken,
  label,
}: {
  downloadUrl: string;
  accessToken: string;
  filename: string;
  label?: string;
}) => {
  return (
    <a
      title={filename}
      style={{ marginRight: "7px" }}
      onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        initiateDownload(downloadUrl, accessToken, filename);
      }}
      href={downloadUrl}
    >
      {label || filename}
    </a>
  );
};

export default SSOALink;
