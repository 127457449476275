import { Laptop } from "lucide-react";
import cx from "classnames";
import { FC } from "react";

import {
  formatDateTimeStringWithAgo,
  PingLucideIcon,
} from "@repo/ping-react-js";

import { HeadingSchemaOptions } from "features/submission-dashboard/PingRichTextInputField";

import { ActivityItemType } from "ts-types/ApiTypes";

import "./PingVisionSubmissionActivityItem.scss";

type PingVisionSubmissionActivityItemProps = {
  historyItem: ActivityItemType;
};

export const PingVisionSubmissionActivityItem: FC<
  PingVisionSubmissionActivityItemProps
> = ({ historyItem }) => {
  let username = historyItem.actor_username;
  let avatarBody = null;
  let lucideIconAvatar = null;
  if (!username) {
    username = "System";
    // avatarBody = "?";
    lucideIconAvatar = "Laptop";
  }
  if (username === "system@pingdatatechnology.com") {
    username = "System";
    lucideIconAvatar = "Laptop";
    // avatarBody = <PingLucideIcon name={lucideIconAvatar} />;
  }
  if (username.startsWith("ping-api-delegated.")) {
    username = "System";
    lucideIconAvatar = "Laptop";
    // avatarBody = <Laptop style={lucideIconStyle} />;
  }

  let avatar = null;
  if (!avatarBody) {
    if (lucideIconAvatar) {
      avatar = (
        <div
          className="PVNotes__avatar PVNotes__avatar__lucide"
          title={username}
        >
          <PingLucideIcon iconName={lucideIconAvatar} size="15px" />
        </div>
      );
    } else {
      let avatarImageSrc = historyItem.avatar;
      avatarBody = avatarImageSrc ? (
        <img
          src={historyItem.avatar}
          alt={historyItem.name}
          className="PVNotes__avatar-image"
        />
      ) : (
        username?.slice(0, 2)?.toUpperCase()
      );

      avatar = avatarBody ? (
        <div className="PVNotes__avatar" title={username}>
          {avatarBody}
        </div>
      ) : null;
    }
  }

  let twoLineFormat = false;
  let change_desc = null;

  if (historyItem.verb === "commented") {
    twoLineFormat = true;
    change_desc = historyItem?.messages[0];
  } else {
    change_desc = historyItem?.messages?.map((m, ii) => {
      const comma_and_after =
        ii === historyItem?.messages?.length - 2
          ? ", and "
          : ii === historyItem?.messages?.length - 1
            ? ""
            : ", ";
      if (m.field) {
        // print the standard "x changed y to z" format
        return (
          <span key={ii}>
            {m.field || "unknown_field"} to{" "}
            <b>{m.to_value || "unknown value"}</b>
            {comma_and_after}
          </span>
        );
      } else {
        // just print the message
        return (
          <span key={ii}>
            {m}
            {comma_and_after}
          </span>
        );
      }
    });

    change_desc = change_desc.reduce((acc, curr) => [acc, " ", curr]);
  }

  return (
    <div
      key={historyItem.id}
      className={cx("PVNotes__note", "PVNotes__note__" + historyItem.verb)}
    >
      <div
        className={cx(
          "PVNotes__content",
          "PVNotes__content__" + historyItem.verb
        )}
      >
        {avatar}
        <p className="PVNotes__author">
          {username}{" "}
          <span className="PVNotes__timestamp">
            {historyItem.verb} {!twoLineFormat ? change_desc : null} ·{" "}
            {formatDateTimeStringWithAgo(historyItem.timestamp)}
          </span>
        </p>
        {twoLineFormat ? <p className="PVNotes__text">{change_desc}</p> : null}
      </div>
    </div>
  );
};
