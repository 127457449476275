import { useRef, useState } from "react";
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useListNavigation,
  useInteractions,
  FloatingFocusManager,
  offset,
  flip,
  size,
  autoUpdate,
  FloatingPortal,
  Placement,
} from "@floating-ui/react";
import cx from "classnames";

import { PingMaterialIcon } from "../icons/PingMaterialIcon";

// import {
//   PingTooltip,
//   PingTooltipContent,
//   PingTooltipTrigger,
// } from "@repo/ping-react-js";

import "./PingFloatingSelectInput.scss";

export type PingFloatingSelectInputOption = {
  label: string;
  isSectionDivider?: boolean;
  disabled?: boolean;
  sectionDisabledMsg?: string;
};

type PingFloatingSelectInputProps = {
  options: PingFloatingSelectInputOption[];
  renderLabelElt: (
    selectedItemLabel: string | null,
    isOpen: boolean
  ) => JSX.Element;
  selectedIndex: number | null;
  setSelectedIndex: (index: number) => void;
  placement?: Placement;
  disabled?: boolean;
  disabledMsg?: string;
};

export const PingFloatingSelectInput = ({
  options,
  renderLabelElt,
  selectedIndex,
  setSelectedIndex,
  disabled,
  placement = "bottom-start",
  disabledMsg = "This option is disabled",
}: PingFloatingSelectInputProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const { refs, floatingStyles, context } = useFloating<HTMLElement>({
    placement,
    open: isOpen,
    onOpenChange: (open) => {
      if (disabled) {
        return;
      }
      setIsOpen(open);
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({ padding: 10 }),
      size({
        apply({ rects, elements, availableHeight }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            minWidth: `${rects.reference.width}px`,
          });
        },
        padding: 10,
      }),
    ],
  });

  const listRef = useRef<Array<HTMLElement | null>>([]);

  const click = useClick(context, { event: "mousedown" });
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "listbox" });
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    loop: true,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [dismiss, role, listNav, click]
  );

  const handleSelect = (index: number) => {
    if (options[index]?.disabled) {
      return;
    }
    setSelectedIndex(index);
    setActiveIndex(null);
    setIsOpen(false);
  };

  const selectedItemLabel =
    selectedIndex !== null ? options[selectedIndex]?.label : null;

  const hasSectionDivider = options.some((o) => o.isSectionDivider);

  return (
    <>
      <div
        className="PingFloatingSelectInput__Trigger"
        title={disabled ? disabledMsg : null}
        tabIndex={0}
        ref={refs.setReference}
        aria-labelledby="select-label"
        aria-autocomplete="none"
        {...getReferenceProps()}
      >
        {renderLabelElt(selectedItemLabel, isOpen)}
      </div>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <div
              ref={refs.setFloating}
              className="PingFloatingSelectInput__MenuContainer"
              style={{ ...floatingStyles }}
              {...getFloatingProps()}
            >
              {options.map((value, i) => {
                if (value.isSectionDivider) {
                  return (
                    <div
                      className="PingFloatingSelectInput__MenuContainer__SectionDivider"
                      key={value.label}
                    >
                      {value?.label}
                      {value.disabled && (
                        <div className="PingFloatingSelectInput__MenuContainer__SectionDivider__DisabledMessage">
                          {value.sectionDisabledMsg}
                        </div>
                      )}
                    </div>
                  );
                }

                return (
                  <div
                    className={cx(
                      "PingFloatingSelectInput__MenuContainer__Option",
                      {
                        "PingFloatingSelectInput__MenuContainer__Option--Active":
                          i === activeIndex,
                        "PingFloatingSelectInput__MenuContainer__Option--Disabled":
                          value?.disabled,
                        "PingFloatingSelectInput__MenuContainer__Option--HasExtraPadding":
                          hasSectionDivider,
                      }
                    )}
                    key={value.label}
                    ref={(node) => {
                      listRef.current[i] = node;
                    }}
                    role="option"
                    tabIndex={i === activeIndex ? 0 : -1}
                    aria-selected={i === selectedIndex && i === activeIndex}
                    {...getItemProps({
                      // Handle pointer select.
                      onClick() {
                        handleSelect(i);
                      },

                      // Handle keyboard select.
                      onKeyDown(event) {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleSelect(i);
                        }
                      },
                    })}
                  >
                    <div>{value.label}</div>
                    {i === selectedIndex ? (
                      <PingMaterialIcon
                        iconName="check"
                        className="PingFloatingSelectInput__MenuContainer__Option__Icon"
                      />
                    ) : null}
                  </div>
                );
              })}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};
