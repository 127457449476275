import { FC, ReactNode } from "react";

import "./PVPanel.scss";

type PVPanelProps = {
  children?: ReactNode;
  background?: string;
};

export const PVPanel: FC<PVPanelProps> = ({ children, background }) => {
  if (background === "none") {
    return <div className="PVPanelNoBackground">{children}</div>;
  } else {
    return <div className="PVPanel">{children}</div>;
  }
};
