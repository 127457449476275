import { useEffect, useState } from "react";
import { ActivityItemType } from "ts-types/ApiTypes";

export const useSubmissionHistory = ({ submissionHistory }) => {
  const [mergedHistory, setMergedHistory] = useState<ActivityItemType[]>([]);

  useEffect(() => {
    if (!submissionHistory) {
      setMergedHistory([]);
      return;
    }

    const history = submissionHistory || [];

    const merged = [...history].sort(
      (a, b) =>
        new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
    );

    setMergedHistory(merged);
  }, [submissionHistory]);

  return mergedHistory;
};
