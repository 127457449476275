import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Panel, PanelGroup } from "react-resizable-panels";
import { useWindowSize } from "@uidotdev/usehooks";
import isNumber from "lodash/isNumber";

import {
  BaseLayout,
  MainContent,
  PingPanelResizeHandle,
} from "@repo/ping-react-js";

import { PVPanel } from "features/submission-dashboard/PVPanel";
import { PingVisionSubmissionList } from "features/submission-dashboard/PingVisionSubmissionList";
import { PingVisionSubmissionDetails } from "features/submission-dashboard/PingVisionSubmissionDetails";
import { PingVisionSidebar } from "features/submission-dashboard/PingVisionSidebar";
import { PVDocumentPreviewPanel } from "features/submission-dashboard/PVDocumentPreviewPanel";
import { PingCommandMenuModal } from "features/submission-dashboard/PingCommandMenu";
import { PVEmptyPanelMessage } from "features/submission-dashboard/PVEmptyPanelMessage";
import {
  useGetSubmissionList,
  useGetSubmissionHistoryList,
} from "features/submission-dashboard/queries";
import {
  useGetEnvironmentQuery,
  useGetSettingsQuery,
  useGetNavQuery,
} from "services/pvSlice";
import { useAppSelector } from "utils/redux";
import { useSlug, usePingId } from "utils/hooks";
import { setSovs, setSubmissionHistory, setPreviewEmail } from "reducers/inbox";
import { setIsCommandMenuOpened } from "reducers/settings";
import { SovDataType } from "ts-types/DataTypes";

import "./PingVisionSubmissionDashboard.scss";

const AUTO_FOCUS_MODE_SCREEN_WIDTH_PX = 1440;

const enrichSovData = (
  sovData: { results: SovDataType[] } | undefined,
  submissionStatuses: { id: string; name: string }[] | undefined,
) => {
  if (!sovData || !submissionStatuses) {
    return [];
  }

  return sovData.results.map((r) => ({
    ...r,
    workflow_status__name:
      submissionStatuses.find(
        (s) => s.id.toString() === r.workflow_status_id.toString(),
      )?.name || "Unknown",
  }));
};

const PingVisionSubmissionDashboard = () => {
  const dispatch = useDispatch();
  const pingId = usePingId();

  const sovs = useAppSelector((state) => state.inbox.sovs);
  const submissionStatuses = useAppSelector(
    (state) => state.settings?.settings?.submission_status,
  );

  const documentPreview = useAppSelector(
    (state) => state.inbox.documentPreview,
  );

  const slug = useSlug();

  const isCommandMenuOpened = useAppSelector(
    (state) => state.settings.isCommandMenuOpened,
  );

  const selectedItem = useMemo(
    () => sovs.find((sov) => sov.id === pingId),
    [sovs, pingId],
  );

  const [localSelectedItem, setLocalSelectedItem] = useState(selectedItem);

  useEffect(() => {
    setLocalSelectedItem(selectedItem);
  }, [selectedItem]);

  const { data: sovData } = useGetSubmissionList();

  useGetSettingsQuery({});

  useGetNavQuery({});

  useGetEnvironmentQuery();

  const { data: sovHistoryData = [] } = useGetSubmissionHistoryList();

  useEffect(() => {
    dispatch(setSubmissionHistory(sovHistoryData));
  }, [dispatch, sovHistoryData]);

  useEffect(() => {
    const enrichedSovs = enrichSovData(sovData, submissionStatuses);
    dispatch(setSovs(enrichedSovs));
  }, [dispatch, submissionStatuses, sovData]);

  useEffect(() => {
    const emlIndex = localSelectedItem?.documents?.findIndex(
      (d) => d.document_type === "EML",
    );
    if (pingId && isNumber(emlIndex) && emlIndex > -1) {
      dispatch(setPreviewEmail(pingId));
    }
  }, [dispatch, pingId, localSelectedItem]);

  const [isFocusModeEnabled, setIsFocusModeEnabled] = useState(false);
  const windowSize = useWindowSize();
  useEffect(() => {
    if (
      documentPreview &&
      windowSize.width &&
      windowSize.width <= AUTO_FOCUS_MODE_SCREEN_WIDTH_PX
    ) {
      setIsFocusModeEnabled(true);
    } else {
      setIsFocusModeEnabled(false);
    }
  }, [windowSize, documentPreview]);

  return (
    <React.Fragment>
      <PingCommandMenuModal
        isOpen={isCommandMenuOpened}
        onClose={() => {
          dispatch(setIsCommandMenuOpened(false));
        }}
      />
      <BaseLayout
        title="Ping Radar [beta]"
        shouldShowPoweredByPing={false}
        className="PingVisionSubmissionDashboard"
      >
        <MainContent
          hasTopPadding={false}
          paddingSize="slim"
          className="PingVisionSubmissionDashboard__Main"
        >
          <PanelGroup direction="horizontal" autoSaveId="submission-dashboard">
            {!isFocusModeEnabled && (
              <>
                <Panel
                  className="PingVisionSubmissionDashboard__Main__Panel"
                  id="submission-dashboard-sidebar"
                  order={1}
                  minSize={8}
                  maxSize={12}
                >
                  <PVPanel background="none">
                    <PingVisionSidebar />
                  </PVPanel>
                </Panel>
                <PingPanelResizeHandle orientation="horizontal" width="slim" />
              </>
            )}
            {slug && !isFocusModeEnabled && (
              <>
                <Panel
                  className="PingVisionSubmissionDashboard__Main__Panel"
                  id="submission-dashboard-submission-list"
                  order={2}
                  minSize={10}
                  maxSize={30}
                >
                  <PVPanel>
                    <PingVisionSubmissionList
                      sovs={sovs}
                      selectedItemId={pingId}
                    />
                  </PVPanel>
                </Panel>
                <PingPanelResizeHandle orientation="horizontal" width="slim" />
              </>
            )}
            <Panel
              className="PingVisionSubmissionDashboard__Main__Panel"
              id="submission-dashboard-submission-details"
              order={3}
              minSize={25}
              maxSize={70}
            >
              {localSelectedItem ? (
                <PVPanel>
                  <PingVisionSubmissionDetails
                    selectedItem={localSelectedItem}
                  />
                </PVPanel>
              ) : (
                <PVPanel>
                  <PVEmptyPanelMessage message="Select a submission to view details" />
                </PVPanel>
              )}
            </Panel>
            <PingPanelResizeHandle orientation="horizontal" width="slim" />

            {documentPreview && selectedItem && (
              <>
                <Panel
                  className="PingVisionSubmissionDashboard__Main__Panel"
                  id="submission-dashboard-preview-panel"
                  order={4}
                  minSize={25}
                  maxSize={70}
                >
                  <PVPanel>
                    <PVDocumentPreviewPanel
                      selectedItem={selectedItem}
                      sovid={selectedItem.id}
                    />
                  </PVPanel>
                </Panel>
              </>
            )}
          </PanelGroup>
        </MainContent>
      </BaseLayout>
    </React.Fragment>
  );
};

export default PingVisionSubmissionDashboard;
