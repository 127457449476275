import { useEffect, useState, useCallback } from "react";
import { Panel, PanelGroup } from "react-resizable-panels";

import { useAppSelector } from "utils/redux";

import { PING_VISION_DEFAULT_FIELDS } from "constants/ApiConstants";
import {
  useBulkUpdateSubmissionMutation,
  useChangeSubmissionTriageStatusMutation,
} from "services/pvSlice";

import delay from "lodash/delay";

import {
  PingDataGrid,
  BaseLayout,
  SiteHeader,
  MainContent,
  useAuth,
} from "@repo/ping-react-js";

import { useGetSubmissionsQuery } from "services/pvSlice";
import { usePingVisionColumns } from "./pingVisionColumns";

import "./PingVisionSubmissionGrid.scss";

const PingVisionSubmissionGrid = () => {
  const [rows, setRows] = useState<any>([]);
  const [delaySubmissionCall, setDelaySubmissionCall] = useState(true);
  const { ssoUser } = useAuth();

  const baseUrl = useAppSelector((state) => state.settings.baseUrl);
  const env = useAppSelector((state) => state.settings.env);
  const accessToken = useAppSelector((state) => state.auth.accessToken);
  const isPingEmployee = useAppSelector(
    (state) => state.settings.isPingEmployee
  );

  const [claimSubmissionTriage] = useBulkUpdateSubmissionMutation();

  const handleClaimSubmission = useCallback(
    async (id: string) => {
      try {
        await claimSubmissionTriage({
          id,
          claimed_by_id: ssoUser?.userId,
        });
        console.log("Submission claimed successfully");
      } catch (error) {
        console.error("Failed to claim submission:", error);
      }
    },
    [ssoUser, claimSubmissionTriage]
  );

  const handleSubmissionStatusMutation = useCallback(
    async (id: string, status: string) => {
      try {
        await useChangeSubmissionTriageStatusMutation({
          id,
          status,
        });
      } catch (error) {
        console.error("Failed to change submission status:", error);
      }
    },
    [ssoUser]
  );
  const { columns } = usePingVisionColumns({
    accessToken,
    claimSubmission: handleClaimSubmission,
    changeSubmissionStatus: handleSubmissionStatusMutation,
    baseUrl: baseUrl,
    env: env,
    isPingEmployee: isPingEmployee,
  });

  const { data: submissionData } = useGetSubmissionsQuery(
    { fields: PING_VISION_DEFAULT_FIELDS },
    { skip: delaySubmissionCall }
  );

  useEffect(() => {
    // @ankur, if you remove htis we call useGetSubmissionsQuery() 3 times, not sure why
    // happens in SAL too
    delay(() => {
      setDelaySubmissionCall(false);
    }, 250);
  }, []);

  useEffect(() => {
    if (submissionData) {
      setRows(submissionData.results);
    }
  }, [submissionData]);

  return (
    <>
      <BaseLayout title={"Ping Radar [beta]"} shouldShowPoweredByPing={false}>
        <SiteHeader>
          <div className="PingVisionApp__Header"></div>
        </SiteHeader>

        <MainContent className="PingVisionApp__Main">
          <PanelGroup
            direction="horizontal"
            autoSaveId="PingVisionApp__Main__PanelGroup"
          >
            <Panel>
              <PanelGroup
                direction="vertical"
                autoSaveId="PingVisionApp__Main__PanelGroup"
              >
                <Panel
                  id="PingVisionApp__DataGrid"
                  className="PingVisionApp__Main__Panel"
                  order={1}
                  minSize={20}
                >
                  <PingDataGrid
                    className="PingVisionApp__Main__Panel__DataGrid"
                    columns={columns}
                    rows={rows}
                    rowClass={(row: Record<string, unknown>) =>
                      row.didAdd
                        ? "PingVisionApp__Main__DataGrid__Row--didAdd"
                        : "PingVisionApp__Main__DataGrid__Row"
                    }
                    rowKeyGetter={(row: any) => row.id}
                  />
                </Panel>
              </PanelGroup>
            </Panel>
          </PanelGroup>
        </MainContent>
      </BaseLayout>
    </>
  );
};

export default PingVisionSubmissionGrid;
