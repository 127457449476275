import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { User } from "@propelauth/react";

type InitialState = {
  accessToken: string;
  user: User | null;
};

const getInitialState = (): InitialState => ({
  accessToken: "",
  user: null,
});

const authSlice = createSlice({
  name: "auth",
  initialState: getInitialState(),
  reducers: {
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload;
    },
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
  },
});

const { reducer } = authSlice;

export const { setUser, setAccessToken } = authSlice.actions;

export default reducer;
