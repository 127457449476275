import cx from "classnames";

import "./DialogHeaderBar.scss";
//
type DialogHeaderBarPropTypes = {
  children?: React.ReactNode;
  className?: string;
  border?: "top" | "bottom";
};

/*
 * A header component to use across dialog boxes, modals, side panels, etc. It's
 * a simple wrapper around a div with some styles applied.
 */
export const DialogHeaderBar = ({
  children,
  className,
  border = "bottom",
}: DialogHeaderBarPropTypes) => {
  const classes = cx(
    "DialogHeaderBar",
    {
      "DialogHeaderBar--borderTop": border === "top",
      "DialogHeaderBar--borderBottom": border === "bottom",
    },
    className
  );

  return <div className={classes}>{children}</div>;
};
