import cx from "classnames";

import "./PingLogo.scss";

export const PoweredByPing = () => {
  return (
    <div
      style={{
        backgroundColor: "white",
        zIndex: 99999999,
        position: "absolute",
        bottom: "0",
        left: "0",
        fontSize: "12px",
        fontWeight: "bold",
        height: "3vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "left",
        color: "#000",
        textAlign: "left",
        width: "15vw",
        padding: "5px 15px",
        opacity: 1,
      }}
    >
      Powered By PING
    </div>
  );
};

type PingLogoProps = {
  isAnimated?: boolean;
  className?: string;
};

export const PingLogo = ({ isAnimated = false, className }: PingLogoProps) => {
  const classes = cx("PingLogo", className, {
    "PingLogo--Animated": isAnimated,
  });

  return (
    <div className={classes}>
      <a className="PingLogo__Link" href={"/activity"}>
        <div></div>
        <div></div>
      </a>
    </div>
  );
};
