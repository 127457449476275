import { FC, useCallback, useState } from "react";
import { z } from "zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

import { PingButton, US_STATES } from "@repo/ping-react-js";

import {
  PVInputsColumn,
  PVInputsColumnsGrid,
} from "features/submission-dashboard/PVFormsLayouts";
import {
  PVErrorDisplay,
  PVSelectInputFormField,
  PVTextareaFormField,
  PVTextInputFormField,
} from "features/submission-dashboard/PVFormFields";
import { PVScrollSpySectionHeader } from "features/submission-dashboard/PVScrollSpy";
import { setServerErrors } from "features/submission-dashboard/set-server-errors";
import { useUpdateSubmissionTriageMutation } from "services/pvSlice";
import { emptyStringToNull, requiredString } from "utils/zod-utils";
import { SovDataType } from "ts-types/DataTypes";

import "./PVInsuredInformationForm.scss";

const formSchema = z.object({
  insured_name: requiredString("Insured name must not be empty"),
  insured_street: emptyStringToNull(),
  insured_street2: emptyStringToNull(),
  insured_city: emptyStringToNull(),
  insured_state: emptyStringToNull(),
  insured_zip: emptyStringToNull(),
  insured_country: emptyStringToNull(),
  home_state: emptyStringToNull(),
  insured_fein: emptyStringToNull(),
  insured_website: emptyStringToNull(),
  insured_business_description: emptyStringToNull(),
});

type FormInput = z.infer<typeof formSchema>;

type PVInsuredInformationFormProps = {
  selectedItem: SovDataType;
};

export const PVInsuredInformationForm: FC<PVInsuredInformationFormProps> = ({
  selectedItem,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm<FormInput>({ resolver: zodResolver(formSchema) });

  const [updateSubmission, { isLoading: isSubmitting }] =
    useUpdateSubmissionTriageMutation();

  const onSubmit: SubmitHandler<FormInput> = useCallback(
    async (data) => {
      const result = await updateSubmission({ id: selectedItem.id, data });
      if (!result.error) {
        setIsEditing(false);
        return;
      }

      setServerErrors(result.error.data, setError);
    },
    [updateSubmission, selectedItem.id, setError]
  );

  const onReset = useCallback(() => {
    reset();
    setIsEditing(false);
  }, [reset]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="PVInsuredInformationForm"
    >
      <PVScrollSpySectionHeader title="Insured Information">
        {isEditing ? (
          <>
            <PingButton
              label="Cancel"
              type="button"
              onClick={onReset}
              disabled={isSubmitting}
            />
            <PingButton
              label={isSubmitting ? "Saving …" : "Save"}
              type="submit"
              variant="primary"
              disabled={isSubmitting}
            />
          </>
        ) : (
          <PingButton
            label="Edit"
            type="button"
            onClick={() => setIsEditing(true)}
          />
        )}
      </PVScrollSpySectionHeader>

      <PVInputsColumnsGrid>
        <PVInputsColumn title="Address">
          {errors.root?.serverError && (
            <PVErrorDisplay>{errors.root?.serverError.message}</PVErrorDisplay>
          )}

          <PVTextInputFormField
            label="Insured Name"
            name="insured_name"
            id="insured_name"
            currentValue={selectedItem.insured_name}
            register={register}
            error={errors.insured_name}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="Insured Street"
            name="insured_street"
            id="insured_street"
            currentValue={selectedItem.insured_street}
            register={register}
            error={errors.insured_street}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="Insured Street 2"
            name="insured_street2"
            id="insured_street2"
            currentValue={selectedItem.insured_street2}
            register={register}
            error={errors.insured_street2}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="Insured City"
            name="insured_city"
            id="insured_city"
            currentValue={selectedItem.insured_city}
            register={register}
            error={errors.insured_city}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVSelectInputFormField
            label="State"
            name="insured_state"
            options={[{ label: "Select state", value: "" }].concat(
              US_STATES.map((s) => ({ label: s.label, value: s.label }))
            )}
            register={register}
            currentValue={selectedItem.insured_state || ""}
            error={errors.insured_state}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVTextInputFormField
            label="Insured ZIP"
            name="insured_zip"
            id="insured_zip"
            currentValue={selectedItem.insured_zip}
            register={register}
            error={errors.insured_zip}
            readOnly={isSubmitting}
            isEditing={isEditing}
          />

          <PVSelectInputFormField
            label="Insured Country"
            name="insured_country"
            options={[
              { label: "Select country", value: "" },
              { label: "United States of America", value: "usa" },
              { label: "United Kingdom", value: "uk" },
              { label: "India", value: "in" },
            ]}
            register={register}
            currentValue={selectedItem.insured_country || ""}
            isEditing={isEditing}
            readOnly={isSubmitting}
            error={errors.insured_country}
          />
        </PVInputsColumn>

        <PVInputsColumn title="Other">
          <PVSelectInputFormField
            label="Home State"
            name="home_state"
            options={[{ label: "Select home state", value: "" }].concat(
              US_STATES.map((s) => ({ label: s.label, value: s.label }))
            )}
            register={register}
            currentValue={selectedItem.home_state || ""}
            isEditing={isEditing}
            readOnly={isSubmitting}
            error={errors.home_state}
          />

          <PVTextInputFormField
            label="Insured FEIN"
            name="insured_fein"
            id="insured_fein"
            currentValue={selectedItem.insured_fein}
            register={register}
            error={errors.insured_fein}
            isEditing={isEditing}
            readOnly={isSubmitting}
          />

          <PVTextInputFormField
            label="Insured Website"
            name="insured_website"
            id="insured_website"
            currentValue={selectedItem.insured_website}
            register={register}
            error={errors.insured_website}
            isEditing={isEditing}
            readOnly={isSubmitting}
          />

          <PVTextareaFormField
            label="Business Descriptions"
            name="insured_business_description"
            id="insured_business_description"
            register={register}
            currentValue={selectedItem.insured_business_description || ""}
            readOnly={isSubmitting}
            isEditing={isEditing}
            error={errors.insured_business_description}
          />
        </PVInputsColumn>
      </PVInputsColumnsGrid>
    </form>
  );
};
