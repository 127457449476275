import cx from "classnames";

import "./PingMaterialIcon.scss";

//

export type PingMaterialIconProps = {
  iconName: string;
  className?: string;
  inheritColor?: boolean;
  href?: string | null;
  target?: string | null;
  title?: string | null;
  disabled?: boolean;
};

export const PingMaterialIcon = ({
  iconName,
  className,
  inheritColor,
  href = null,
  ...restProps
}: PingMaterialIconProps) => {
  const classes = cx("PingMaterialIcon", "material-icons", className, {
    "PingMaterialIcon--InheritColor": inheritColor,
  });

  if (href) {
    // do not underline
    return (
      <a className={classes} href={href} {...restProps}>
        {iconName}
      </a>
    );
  } else {
    return (
      <span className={classes} {...restProps}>
        {iconName}
      </span>
    );
  }
};
