import { FC, HTMLProps } from "react";
import cx from "classnames";

import { PingButton } from "@repo/ping-react-js";

import { PVSectionSubHeading } from "features/submission-dashboard/PVHeadings";

import "./PVFormsLayouts.scss";

type PVInputsColumnProps = {
  title?: string;
  fitLabelContent?: boolean;
} & HTMLProps<HTMLDivElement>;

const PVInputsColumn: FC<PVInputsColumnProps> = ({
  title,
  fitLabelContent = false,
  children,
  ...restProps
}) => {
  const appliedClasses = cx("PVInputsColumn", {
    "PVInputsColumn--FitLabelContent": fitLabelContent,
  });

  return (
    <div {...restProps} className={appliedClasses}>
      {title && (
        <PVSectionSubHeading className="PVInputsColumn__Title">
          {title}
        </PVSectionSubHeading>
      )}
      {children}
    </div>
  );
};

type PVInputsColumnsGridProps = HTMLProps<HTMLDivElement>;

const PVInputsColumnsGrid: FC<PVInputsColumnsGridProps> = ({
  children,
  ...restProps
}) => {
  return (
    <div {...restProps} className="PVInputsColumnsGrid">
      {children}
    </div>
  );
};

type PVReadOnlyValueProps = HTMLProps<HTMLDivElement>;

const PVReadOnlyValue: FC<PVReadOnlyValueProps> = ({
  children,
  ...restProps
}) => {
  return (
    <div {...restProps} className="PVReadOnlyValue">
      {children}
    </div>
  );
};

type PVFormSectionActionsProps = {
  isEditing: boolean;
  formId: string;
  onEdit(): void;
  onCancelEdit(): void;
};

const PVFormSectionActions: FC<PVFormSectionActionsProps> = ({
  isEditing,
  formId,
  onEdit,
  onCancelEdit,
}) => {
  if (isEditing) {
    return (
      <>
        <PingButton
          label="Cancel"
          type="reset"
          form={formId}
          onClick={onCancelEdit}
        />
        <PingButton
          label="Save"
          type="submit"
          variant="primary"
          form={formId}
        />
      </>
    );
  }

  return <PingButton label="Edit" onClick={onEdit} />;
};

export {
  PVInputsColumn,
  PVInputsColumnsGrid,
  PVReadOnlyValue,
  PVFormSectionActions,
};
