import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

export const useQuery = () => {
  const location = useLocation();
  return useMemo(
    // https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
    () => new URLSearchParams(location.search),
    [location.search]
  );
};

/**
 * A hook to get the values of a number of user-supplied query parameters from
 * the current URL as key-value pairs.
 */
export const useQueryParams = (...params: string[]) => {
  const query = useQuery();
  const queryParams = useParams();
  return params.reduce(
    (acc, param) => {
      acc[param] = query.get(param) || queryParams?.[param];
      return acc;
    },
    {} as Record<string, string | null>
  );
};
