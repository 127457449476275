import { Circle, CircleAlert, CircleCheck, CircleX } from "lucide-react";

const SeverityIndicator = ({ readinessScore }: { readinessScore: number }) => {
  const getIndicator = (score: number) => {
    if (!score) return <Circle size={16} color="black" />
    if (score <= 0.5) return <CircleX size={16} color="#ff2e2e" />;
    if (score <= 0.75) return <CircleAlert size={16} color="#c4c700" />;
    if (score <= 0.9) return <Circle size={16} color="black" />;
    return <CircleCheck size={16} color="green" />;
  };

  return (
    <span style={{ display: 'inline-block', width: '20px', textAlign: 'center', marginRight: "5px" }}>{getIndicator(readinessScore)}</span>
  );
};

export default SeverityIndicator;