import { History } from "history";

export type PingQueryParameterType = null | string | string[];

const setQueryParameters = (
  query: URLSearchParams,
  history: History,
  paramsMap: Record<string, PingQueryParameterType>,
  replace: boolean = false
) => {
  for (const [param, value] of Object.entries(paramsMap)) {
    if (
      value === "" ||
      value === null ||
      (Array.isArray(value) && value.length === 0)
    ) {
      query.delete(param);
    } else if (Array.isArray(value)) {
      query.delete(param);
      value.forEach((v) => query.append(param, v));
    } else {
      query.set(param, value.toString());
    }
  }

  if (replace) {
    history.replace({
      search: query.toString(),
    });
  } else {
    history.push({
      search: query.toString(),
    });
  }
};

export const pushQueryParameters = (
  query: URLSearchParams,
  history: History,
  paramsMap: Record<string, PingQueryParameterType>
) => setQueryParameters(query, history, paramsMap, false);

export const replaceQueryParameters = (
  query: URLSearchParams,
  history: History,
  paramsMap: Record<string, PingQueryParameterType>
) => setQueryParameters(query, history, paramsMap, true);
