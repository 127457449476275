import { useContext, createContext } from "react";
import { History, Location } from "history";
import type {
  User,
  UserClass,
  OrgHelper,
  AccessHelper,
} from "@propelauth/react";

export type IRouterContext = {
  history: History;
  location?: Location;
};

export const RouterContext = createContext<IRouterContext>(
  {} as IRouterContext
);

export const AuthContext = createContext<{
  accessToken: string | null;
  ssoUser: User | null;
  isImpersonating: boolean | null;
  accessTokenExpiresAtSeconds: number | null;
  isLoggedIn: boolean | null;
  userClass: UserClass | null;
  orgHelper: OrgHelper | null;
  impersonatorUserId: string | null;
  accessHelper: AccessHelper | null;
}>({
  accessToken: null,
  ssoUser: null,
  isImpersonating: null,
  accessTokenExpiresAtSeconds: null,
  isLoggedIn: null,
  userClass: null,
  orgHelper: null,
  impersonatorUserId: null,
  accessHelper: null,
});

export const useAuth = () => useContext(AuthContext);
