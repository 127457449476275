import { FC, ReactNode } from "react";
import cx from "classnames";

import { daysBetween, formatShortDateTime } from "@repo/ping-react-js";

import { PVScrollSpySectionHeader } from "features/submission-dashboard/PVScrollSpy";
import { SovDataType } from "ts-types/DataTypes";
import { formatCurrency } from "utils/formatters";

import "./PVSubmissionSummary.scss";

const SOURCE_TYPE_TO_NAME: { [key: string]: string } = {
  E: "Email",
  A: "API",
};

const sourceTypeToName = (sourceType: string) =>
  SOURCE_TYPE_TO_NAME[sourceType] || "Unknown";

const formatAddress = (item: SovDataType) => {
  return (
    <div>
      <div>
        {item.insured_street}, {item.insured_street2},
      </div>
      <div>
        {item.insured_city}, {item.insured_state} {item.insured_zip}
      </div>
    </div>
  );
};

const parseEmail = (email: string | null) => {
  if (!email) {
    return { name: "Unknown" };
  }

  const emailPattern = /^(.*)<(.*)>$/;
  const match = email.match(emailPattern);

  if (match) {
    return { name: match[1].trim(), email: match[2].trim() };
  }

  return { email };
};

const daysUntilOrAgo = (date: string | null) => {
  const daysUntil = daysBetween(new Date(), date);

  let description;
  if (!date || !daysUntil) {
    description = "Unknown";
  } else {
    if (new Date() > new Date(date)) {
      description = `${Math.abs(daysUntil)}d ago`;
    } else {
      description = `in ${daysUntil}d`;
    }
  }

  return description;
};

type PVSubmissionSummaryProps = {
  selectedItem: SovDataType;
};

const PVSubmissionSummary: FC<PVSubmissionSummaryProps> = ({
  selectedItem,
}) => {
  let emailValue;
  let emailSubtitle;
  const parsedEmail = parseEmail(selectedItem.from_email);
  if (parsedEmail.name && parsedEmail.email) {
    emailValue = parsedEmail.name;
    emailSubtitle = parsedEmail.email;
  } else if (parsedEmail.email && !parsedEmail.name) {
    emailValue = parsedEmail.email;
    emailSubtitle = "";
  } else {
    emailValue = "Unknown";
    emailSubtitle = "";
  }

  return (
    <div className="PVSubmissionSummary">
      <PVScrollSpySectionHeader title="Summary" />
      <div className="PVSubmissionSummary__Content">
        <PVSubmissionInfoCard
          title="Source"
          value={sourceTypeToName(selectedItem.source__source_type)}
        />
        {selectedItem.source__source_type === "E" && (
          <PVSubmissionInfoCard
            title="Sender"
            value={emailValue}
            subtitle={emailSubtitle}
          />
        )}
        <PVSubmissionInfoCard
          title="Received"
          value={formatShortDateTime(selectedItem.created_time, "")}
          subtitle={`${daysBetween(selectedItem.created_time, new Date())}d ago`}
        />
        <PVSubmissionInfoCard
          title="Inception"
          value={formatShortDateTime(selectedItem.inception_date, "")}
          subtitle={daysUntilOrAgo(selectedItem.inception_date)}
        />
        <PVSubmissionInfoCard
          title="Expiration"
          value={formatShortDateTime(selectedItem.expiration_date, "")}
          subtitle={daysUntilOrAgo(selectedItem.expiration_date)}
        />
        <PVSubmissionInfoCard
          title="Buildings"
          value={selectedItem.num_buildings?.toString() ?? "Unknown"}
        />
        <PVSubmissionInfoCard
          title="TIV"
          value={
            formatCurrency(selectedItem.limits_total_limit, true) || "Unknown"
          }
        />
        <PVSubmissionInfoCard
          title="Insured address"
          value={formatAddress(selectedItem)}
          uiSize="wide"
        />
      </div>
    </div>
  );
};

type PVSubmissionInfoCardProps = {
  title: string;
  value: ReactNode;
  subtitle?: string;
  uiSize?: "normal" | "wide";
};

const PVSubmissionInfoCard: FC<PVSubmissionInfoCardProps> = ({
  title,
  value,
  subtitle,
  uiSize = "normal",
}) => {
  const appliedClasses = cx("PVSubmissionInfoCard", {
    "PVSubmissionInfoCard--IsWide": uiSize === "wide",
  });

  return (
    <div className={appliedClasses}>
      <div className="PVSubmissionInfoCard__Title">{title}</div>
      <div className="PVSubmissionInfoCard__Value">{value}</div>
      {subtitle && (
        <div className="PVSubmissionInfoCard__Subtitle">{subtitle}</div>
      )}
    </div>
  );
};

export { PVSubmissionSummary };
