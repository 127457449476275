import isNumber from "lodash/isNumber";
import isNaN from "lodash/isNaN";
import isNull from "lodash/isNull";
import { isUndefined } from "lodash";

export const formatCurrency = (c: any, abbr?: boolean) => {
  let value = c;
  if (!isNumber(c)) {
    value = parseFloat((c || "").toString());
    value = Math.fround(value);
  }
  if (c === 0) {
    return "$0";
  }

  if (isNaN(value)) {
    return "";
  }

  // Check if the `abbr` parameter is set to `true`
  if (abbr) {
    // Define an array of abbreviations to use for large numbers
    const abbreviations = ["", "K", "M", "B", "T"];

    // Determine the appropriate abbreviation to use based on the number's magnitude
    const magnitude = Math.floor(Math.log10(Math.abs(value)) / 3);
    const abbreviation = abbreviations[magnitude];

    // Divide the number by the appropriate power of 10 and round to 1 decimal place
    value = value / Math.pow(10, magnitude * 3);
    value = Math.round(value * 10) / 10;

    // Append the abbreviation to the formatted string
    return value > 0
      ? "$" + value + abbreviation
      : "-$" + -1 * value + abbreviation;
  } else {
    // Use the default currency formatting
    return value.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    });
  }
};

export const formatWithCommas = (
  value: string | number | undefined,
  roundToZero = true
): string => {
  if (isNaN(value) || isUndefined(value) || isNull(value)) {
    return "";
  }

  if (!value) {
    return `${value}`;
  }
  if (typeof value === "string") {
    value = parseFloat(value);
  }
  const formatter = new Intl.NumberFormat("en-US");
  const formattedValue = roundToZero ? value?.toFixed(0) : value?.toFixed(2);
  return formatter.format(parseFloat(formattedValue));
};

export const parseCurrency = (c: string) => {
  if (c === "") {
    return 0;
  }
  return parseFloat(c.replace(/,/g, ""));
};

export const formatPercentage = (value: number) => {
  if (isNaN(value) || isUndefined(value) || isNull(value)) {
    return "";
  }

  return `${value.toFixed(2)}%`;
};
