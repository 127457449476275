import { Toaster, toast, ToastPosition } from "react-hot-toast";
import "./PingToaster.scss";
import { PingMaterialIcon } from "../icons/PingMaterialIcon";

type PingToasterProps = {
  position?: ToastPosition;
};

const refreshPage = () => {
  window.location.reload();
};

const RefreshToast = ({
  message,
  onIgnore,
}: {
  message: string;
  onIgnore: () => void;
}) => (
  <div className="PingToaster--Refresh">
    <div className="message">{message}</div>
    <div className="actions">
      <button className="action-button refresh-button" onClick={refreshPage}>
        <PingMaterialIcon iconName="refresh" />
      </button>
      <button className="action-button ignore-button" onClick={onIgnore}>
        <PingMaterialIcon iconName="close" />
      </button>
    </div>
  </div>
);

export const PingToaster = ({ ...rest }: PingToasterProps) => {
  return (
    <Toaster
      {...rest}
      toastOptions={{
        success: {
          duration: 4000,
          className: "PingToaster PingToaster--Success",
        },
        error: {
          className: "PingToaster PingToaster--Error",
        },
        custom: {
          className: "PingToaster PingToaster--Custom",
        },
        loading: {
          className: "PingToaster PingToaster--Loading",
        },
      }}
    />
  );
};

export const refreshToast = (message: string, onClose?: () => void) => {
  const toastId = toast.custom(
    (t) => (
      <RefreshToast
        message={message}
        onIgnore={() => {
          toast.remove(t.id);
          onClose?.();
        }}
      />
    ),
    {
      duration: Infinity,
    }
  );
  return toastId;
};
