import { FC, useCallback, useRef } from "react";
import Frame, { FrameContextConsumer } from "react-frame-component";

import { PingCircleIconButton } from "../inputs/PingCircleIconButton";

import "./PingEmailPreview.scss";

interface EmailThread {
  subject: string;
  html: string;
}

type PingEmailPreviewProps = {
  content: EmailThread[];
  onClose(): void;
};

export const PingEmailPreview: FC<PingEmailPreviewProps> = ({
  content,
  onClose,
}) => {
  let messageCount = "No messages";
  if (content.length > 0) {
    messageCount = `${content.length} ${content.length > 1 ? "messages" : "message"}`;
  }

  const frameRef = useRef(null);
  const frameDocumentRef = useRef<null | Document>(null);

  const onLoadFrame = useCallback(() => {
    const height = Math.max(
      frameDocumentRef.current.documentElement.clientHeight,
      frameDocumentRef.current.documentElement.scrollHeight,
      frameDocumentRef.current.documentElement.offsetHeight,
      frameDocumentRef.current.body.scrollHeight,
      frameDocumentRef.current.body.offsetHeight
    );

    frameRef.current.style.height = `${height}px`;
  }, []);

  return (
    <div className="PingEmailPreview">
      <div className="PingEmailPreview__Controls">
        <div className="PingEmailPreview__Controls_NavigationButtons">
          {onClose && (
            <PingCircleIconButton iconName="close" onClick={onClose} />
          )}
        </div>
        <div className="PingEmailPreview__Controls_PageInfo">
          {messageCount}
        </div>
      </div>
      {content.map((email, index) => (
        <div key={index} className="PingEmailPreview__EmailThread">
          <div className="PingEmailPreview__EmailThread__Header">
            <h3 className="PingEmailPreview__EmailThread__Header__Subject">
              {email.subject}
            </h3>
          </div>
          <Frame className="PingEmailPreview__EmailThread__Body" ref={frameRef}>
            <FrameContextConsumer>
              {({ document }) => {
                if (!frameDocumentRef.current) {
                  frameDocumentRef.current = document;
                  setTimeout(() => onLoadFrame(), 0);
                }
                return <div dangerouslySetInnerHTML={{ __html: email.html }} />;
              }}
            </FrameContextConsumer>
          </Frame>
        </div>
      ))}
    </div>
  );
};
