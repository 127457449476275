import { memo, Fragment, Key } from "react";
import cx from "classnames";
import DataGrid, {
  DataGridHandle,
  DataGridProps,
} from "react-data-grid";

import "./PingDataGrid.scss";

type RowWithId<K> = { id: K };

type PingDataGridProps<
  R extends RowWithId<K>,
  SR,
  K extends Key,
> = DataGridProps<R, SR, K> & {
  isFetching?: boolean;
  shouldSuppressBorder?: boolean;
  selectedRowIds?: K[];
  rowClass?: (row: R, rowIdx: number) => string;
  setParentRef?: (ref: DataGridHandle | null) => void;
};

export const PingDataGrid = <
  R extends RowWithId<K>,
  SR = unknown,
  K extends Key = Key,
>({
  columns,
  className,
  isFetching = false,
  shouldSuppressBorder = false,
  selectedRowIds,
  rowClass,
  setParentRef,
  ...restProps
}: PingDataGridProps<R, SR, K>) => {
  const appliedClasses = cx("rdg-light PingDataGrid", className, {
    "PingDataGrid--SuppressBorder": shouldSuppressBorder,
  });

  return (
    <DataGrid
      ref={(refValue) => setParentRef?.(refValue)}
      className={appliedClasses}
      renderers={{
        noRowsFallback: isFetching ? (
          <LoadingRowsRenderer numColumns={columns.length} />
        ) : null,
      }}
      rowHeight={32}
      headerRowHeight={36}
      columns={columns}
      rowClass={(row, rowIdx) => {
        const parentRowClass = rowClass?.(row, rowIdx);
        const isSelected = selectedRowIds?.includes(row.id) ?? false;

        return cx(
          "PingDataGrid__Row",
          isSelected && "PingDataGrid__Row--IsSelected",
          parentRowClass
        );
      }}
      {...restProps}
    />
  );
};

// Most displays won't have more than 50 rows visible at the same time, so we'll
// use that as the default.
const DEFAULT_NUM_ROWS = 50;

const LoadingRowsRenderer = memo(
  ({
    rows = DEFAULT_NUM_ROWS,
    numColumns: columns,
  }: {
    rows?: number;
    numColumns: number;
  }) => {
    return (
      <div className="PingDataGrid__LoadingRowsRenderer">
        {Array.from({ length: rows }).map((_, i) => (
          <Fragment key={`row-${i}`}>
            {Array.from({ length: columns }).map((_, j) => (
              <div
                key={`cell-${i}-${j}`}
                className="PingDataGrid__LoadingRowsRenderer__Cell rdg-cell"
              ></div>
            ))}
          </Fragment>
        ))}
      </div>
    );
  }
);
