import { useState, useCallback } from "react";
import cx from "classnames";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "utils/redux";
import type { View } from "ts-types/ApiTypes";
import { PingLucideIcon } from "@repo/ping-react-js";
import { FRONT_END_BASE_URL } from "constants/index";
import slugify from "slugify";
import { useSlug, useTeamId } from "utils/hooks";
import lockr from "lockr";

import "./PingVisionSidebar.scss";

const ExpandIcon = ({ expand }: { expand: boolean }) => (
  <PingLucideIcon
    className="PingVisionSidebarSection__Header__Icon"
    iconName={expand ? "ChevronDown" : "ChevronRight"}
  />
);

const PingVisionSidebarSection = ({ name, icon, items, ...rest }: View) => {
  const [expand, setExpand] = useState(lockr.get(name, true));
  const history = useHistory();
  const teamId = useTeamId();
  const slug = useSlug();

  const handleClick = useCallback(
    (item: View, event: React.MouseEvent) => {
      event.preventDefault();
      history.push(
        item.team_name
          ? `${FRONT_END_BASE_URL}/${slugify(item.team_name)}/views/${item.slug}`
          : `${FRONT_END_BASE_URL}/${item.slug}`
      );
    },
    [history]
  );

  const hasItems = items && items?.length > 0;
  const isNestedSection = hasItems && items[0].items;

  if (isNestedSection) {
    return (
      <div className="PingVisionSidebarSection">
        <div
          className={cx("PingVisionSidebarSection__Header")}
          onClick={() => {
            lockr.set(name, !expand);
            setExpand(!expand);
          }}
        >
          <ExpandIcon expand={expand} />
          {name}
        </div>
        {expand && (
          <ul className="PingVisionSidebarSection__List">
            {items.map((item, i) => (
              <PingVisionSidebarSection key={i} {...item} />
            ))}
          </ul>
        )}
      </div>
    );
  }

  if (hasItems) {
    return (
      <div className="PingVisionSidebarSection">
        <div
          className={cx("PingVisionSidebarSection__Header", {
            "PingVisionSidebarSection__Header--Nested": hasItems,
          })}
          onClick={() => {
            lockr.set(name, !expand);
            setExpand(!expand);
          }}
        >
          <ExpandIcon expand={expand} />
          {name}
        </div>
        {expand && (
          <ul className="PingVisionSidebarSection__List">
            {items.map((item, i) => {
              const isActive =
                item.slug === slug && slugify(item?.team_name) === teamId;
              return (
                <li
                  key={i}
                  className={cx("PingVisionSidebarSection__List__Item", {
                    "PingVisionSidebarSection__List__Item--Active": isActive,
                  })}
                >
                  <a
                    href="#"
                    onClick={(e) => handleClick(item, e)}
                    className={cx(
                      "PingVisionSidebarSection__List__Item__Link",
                      {
                        "PingVisionSidebarSection__List__Item__Link--Active":
                          isActive,
                      }
                    )}
                  >
                    <PingLucideIcon
                      className={cx(
                        "PingVisionSidebarSection__List__Item__Link__Icon"
                      )}
                      iconName={item.icon}
                    />
                    <span>{item.name}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }

  return (
    <div className="PingVisionSidebarSection">
      <div
        className={cx("PingVisionSidebarSection__Header", {
          "PingVisionSidebarSection__Header--Active": rest.slug == slug,
        })}
        onClick={(e) => handleClick(rest, e)}
      >
        <PingLucideIcon
          size={16}
          className={cx("PingVisionSidebarSection__Header__Icon")}
          iconName={icon}
        />
        {name}
      </div>
    </div>
  );
};

export const PingVisionSidebar = () => {
  const nav = useAppSelector((state) => state.settings.nav);
  return (
    <nav className="PingVisionSidebar">
      {nav?.views?.map((section, i) => (
        <PingVisionSidebarSection key={i} {...section} />
      ))}
    </nav>
  );
};
