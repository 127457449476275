import cx from "classnames";

import "./PingCircleIconButton.scss";

type PingCircleIconButtonProps = {
  iconName: string;
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const PingCircleIconButton = ({
  iconName,
  className,
  ...restProps
}: PingCircleIconButtonProps) => {
  const classes = cx("PingCircleIconButton", className);
  return (
    <button className={classes} {...restProps}>
      <span className="material-icons">{iconName}</span>
    </button>
  );
};
