import cx from "classnames";
import * as LucideIcons from "lucide-react";

type LucideIconComponent = React.ComponentType<LucideIcons.LucideProps>;

type LucideSpecificProps = {
  size?: string | number;
  absoluteStrokeWidth?: boolean;
};

export type LucideIconName = keyof typeof LucideIcons;

export type PingLucideIconProps = React.SVGProps<SVGSVGElement> &
  LucideSpecificProps & {
    iconName: keyof typeof LucideIcons;
    className?: string;
    inheritColor?: boolean;
    href?: string | null;
    target?: string | null;
    title?: string | null;
    disabled?: boolean;
  };

export const PingLucideIcon: React.FC<PingLucideIconProps> = ({
  iconName,
  className,
  inheritColor,
  href = null,
  size = 20,
  color,
  absoluteStrokeWidth,
  ...restProps
}) => {
  const classes = cx("PingLucideIcon", className, {
    "PingLucideIcon--InheritColor": inheritColor,
  });

  const Icon = LucideIcons[iconName] as LucideIconComponent;

  if (!Icon) {
    console.warn(`Icon "${iconName}" not found in Lucide icons`);
    return null;
  }

  const iconProps: LucideIcons.LucideProps = {
    size,
    color: inheritColor ? "currentColor" : color,
    absoluteStrokeWidth,
    className: classes,
    ...restProps,
  };

  const iconElement = <Icon {...iconProps} />;

  if (href) {
    return (
      <a className={classes} href={href}>
        {iconElement}
      </a>
    );
  } else {
    return iconElement;
  }
};

export default PingLucideIcon;
