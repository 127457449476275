import { useState } from "react";

import { PingVisionModal } from "./PingVisionModal";
import { PingButton, PingTextInput } from "@repo/ping-react-js";

import "./MarkSubmissionDuplicateModal.scss";

const MarkSubmissionDuplicateModal = ({ isOpen, onClose, onClickYes }) => {
  const [duplicateOfSubmissionId, setDuplicateOfSubmissionId] = useState("");
  return (
    <PingVisionModal
      className="MarkSubmissionDuplicateModal"
      isOpen={isOpen}
      onClose={onClose}
      title="Mark Submission as Duplicate"
      renderFooter={() => (
        <>
          <PingButton
            label="Cancel"
            onClick={() => {
              onClose();
            }}
          />
          <PingButton
            label="OK"
            onClick={() => {
              onClickYes(duplicateOfSubmissionId)?.then(() => {
                onClose();
              });
            }}
          />
        </>
      )}
      renderContent={() => (
        <div className="PivotTableModal__Content">
          <div className="PivotTableModal__Content__Text">
            Close this submission on behalf of the selected submission:
          </div>
          <div className="PivotTableModal__Content__Text">
            <br />
            <PingTextInput
              type="text"
              inputClassName=""
              value={duplicateOfSubmissionId}
              onChange={(e) => {
                setDuplicateOfSubmissionId(e.target.value);
              }}
              placeholder={"Enter ping submission id"}
              name="duplicate_of_submission_id"
            />
          </div>
        </div>
      )}
    />
  );
};

export default MarkSubmissionDuplicateModal;
