import { ZodSchema } from "zod";
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";

type TBaseQuery = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  { dataSchema?: ZodSchema },
  FetchBaseQueryMeta
>;

/**
 * HOF that wraps a base query function with additional functionality for data
 * validation using zod.
 */
export const baseQueryWithZodValidation: (
  baseQuery: TBaseQuery,
) => TBaseQuery =
  (baseQuery: TBaseQuery) => async (args, api, extraOptions) => {
    // Call the original baseQuery function with the provided arguments.
    const returnValue = await baseQuery(args, api, extraOptions);

    // Retrieve the data schema from the extraOptions object.
    const zodSchema = extraOptions?.dataSchema;

    const { data } = returnValue;

    if (data && zodSchema) {
      // Throws ZodError if the data fails validation.
      const parseResult = zodSchema.safeParse(data);

      if (!parseResult.success && import.meta.env.DEV) {
        throw parseResult.error;
      } else if (!parseResult.success && import.meta.env.PROD) {
        console.error(
          "Ping API response doesn't match what the frontend app was expecting",
          parseResult.error.format(),
        );
      }
    }

    // Return the original returnValue object
    return returnValue;
  };
