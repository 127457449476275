import isUndefined from "lodash/isUndefined";
import cx from "classnames";

import { PingLogo } from "./PingLogo";
import { SiteHeaderActionItem, SiteHeaderActions } from "./SiteHeaderActions";

import "./SiteHeader.scss";

type SiteHeaderProps = {
  children?: React.ReactNode;
  actions?: SiteHeaderActionItem[];
  className?: string;
};

export const SiteHeader = ({
  children,
  actions,
  className,
}: SiteHeaderProps) => {
  const classes = cx("SiteHeader", className);
  return (
    <header className={classes}>
      <PingLogo className="SiteHeader__PingLogo" isAnimated />
      {children}
      {!isUndefined(actions) && actions.length > 0 && (
        <SiteHeaderActions items={actions} />
      )}
    </header>
  );
};
