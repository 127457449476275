import * as React from "react";
import cx from "classnames";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  FloatingArrow,
  arrow,
} from "@floating-ui/react";
import type { Placement } from "@floating-ui/react";

import variables from "../../styles/common/shared.module.scss";
import "./PingTooltip.scss";

export const DEFAULT_TOOLTIP_OPEN_DELAY = 300;
export const DEFAULT_TOOLTIP_CLOSE_DELAY = 200;

interface PingTooltipOptions {
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  tooltipRole?: string;
}

export function usePingTooltip({
  initialOpen = false,
  placement = "top",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  tooltipRole = "tooltip",
}: PingTooltipOptions = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const arrowRef = React.useRef(null);

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: 5,
      }),
      shift({ padding: 5 }),
      arrow({ element: arrowRef }),
    ],
  });

  const context = data.context;

  const hoverInteraction = useHover(context, {
    move: false,
    enabled: controlledOpen == null,
    delay: {
      open: DEFAULT_TOOLTIP_OPEN_DELAY,
      close: DEFAULT_TOOLTIP_CLOSE_DELAY,
    },
  });
  const focusInteraction = useFocus(context, {
    enabled: controlledOpen == null,
  });
  const dismissInteraction = useDismiss(context);
  const roleInteraction = useRole(context, { role: tooltipRole as any });

  const interactions = useInteractions([
    hoverInteraction,
    focusInteraction,
    dismissInteraction,
    roleInteraction,
  ]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      arrowRef,
      ...interactions,
      ...data,
    }),
    [open, setOpen, arrowRef, interactions, data]
  );
}

type ContextType = ReturnType<typeof usePingTooltip> | null;

const PingTooltipContext = React.createContext<ContextType>(null);

export const usePingTooltipContext = () => {
  const context = React.useContext(PingTooltipContext);

  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }

  return context;
};

export function PingTooltip({
  children,
  ...options
}: { children: React.ReactNode } & PingTooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = usePingTooltip(options);
  return (
    <PingTooltipContext.Provider value={tooltip}>
      {children}
    </PingTooltipContext.Provider>
  );
}

export const PingTooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger(
  { children, className, asChild = false, ...props },
  propRef
) {
  const context = usePingTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      })
    );
  }

  const classes = cx("PingTooltipTrigger", className);

  return (
    <button
      className={classes}
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? "open" : "closed"}
      {...context.getReferenceProps(props)}
    >
      {children}
    </button>
  );
});

export const PingTooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ style, className, children, ...props }, propRef) {
  const context = usePingTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  const classes = cx("PingTooltipContent", className);

  if (!context.open) {
    return null;
  }

  return (
    <FloatingPortal>
      <div
        className={classes}
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...style,
        }}
        {...context.getFloatingProps(props)}
      >
        <FloatingArrow
          ref={context.arrowRef}
          context={context.context}
          fill={variables.colorGreyDark}
          stroke={variables.colorGreyDark}
        />
        {children}
      </div>
    </FloatingPortal>
  );
});
